import { ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { keyBy } from 'lodash';
import Table from './tableComponents/Table';
import { TInput, TTab } from '../tabs/type';
import { isEmpty, strNomalize } from '../../helpers';
import { addressInputs, addressInputskeys } from './actions/ManageInputs';
import useTableContainer from './TableContainer';
import { TState } from '../../redux/store';
import { useCanI } from '../../redux/actions';

export type TColumn = {
  header?: ReactNode;
  accessorKey: string;
  accessorFn?: (row: any, idx: number) => any;
  meta: {
    _id: string;
  };
};

const formatForTable = (
  selectById: Record<string, TInput>,
  userList: Record<string, any>[],
  canI: any,
  columns: any[] = [],
) => {
  return columns?.reduce((prev: any, column: any) => {
    let col: any = [
      {
        id: column.name_id,
        accessorKey: column.label,
        accessorFn: (row: any) => row[column.name_id || ''],
        meta: {
          _id: column.name_id || '',
          type: column.type,
          unity: column.unity,
        },
      },
    ];

    if (
      column.name_id &&
      column.type !== 'address' &&
      !canI(column.name_id, 'read')
    ) {
      return prev;
    }

    if (/select/.test(column.type) && selectById[column.name_id || '']) {
      const select = selectById[column.name_id || ''];
      const name_id = `${select?.name_id}`;

      col = [
        {
          id: column.name_id,
          accessorKey: column.label,
          accessorFn: (row: any) =>
            (!isEmpty(row[name_id]) &&
              (select?.type === 'multiselect'
                ? row[name_id]
                    .map((s: any) => select?.options?.[Number(s)])
                    .join(', ')
                : select?.options?.[Number(row[name_id])])) ||
            '-',
          meta: {
            _id: name_id,
            colors: select?.colors,
            colorKey: name_id,
            type: column.type,
            options: select?.options,
            isMulti: select?.type === 'multiselect',
          },
        },
      ];
    }

    if (column.type === 'users') {
      const usersById = keyBy(userList, '_id');
      const name_id = `${column?.name_id}`;
      const colors = userList.reduce(
        (prev, user) => ({
          ...prev,
          [user._id]: user.userColor,
        }),
        {},
      );

      col = [
        {
          id: column.name_id,
          accessorKey: column.label,
          accessorFn: (row: any) => {
            return (
              usersById[row[name_id]]?.name ||
              usersById[row[name_id]]?.login ||
              '-'
            );
          },
          meta: {
            _id: name_id,
            colors: colors,
            colorKey: name_id,
            type: column.type,
          },
        },
      ];
    }

    if (column.type === 'address') {
      col = addressInputskeys.reduce(
        (prevField, inputKey, idx) =>
          !(column as any)[inputKey] ||
          !canI(`${column.name_id}_${inputKey}`, 'read')
            ? prevField
            : [
                ...prevField,
                {
                  id: `${column.name_id}_${inputKey}`,
                  accessorKey: `${addressInputs[idx]} ${column.label}`,
                  accessorFn: (row: any) =>
                    row[`${column.name_id}_${inputKey}`],
                  meta: {
                    _id: strNomalize(`${column.name_id}_${addressInputs[idx]}`),
                    type: column.type,
                    name: `${addressInputs[idx]} ${column.label}`,
                  },
                },
              ],
        [] as any,
      );

      if (column.showValidation) {
        col.push({
          id: column.name_id,
          accessorKey: `Valide`,
          accessorFn: (row: any) => (row[`${column.name_id}`] ? 'oui' : 'non'),
          meta: {
            _id: `vaidation_${column.label}`,
            type: column.type,
            showValidation: true,
          },
        });
      }
    }

    return [...prev, ...col];
  }, [] as any);
};

const DynamicTable = ({ tab }: { tab?: TTab }) => {
  const data = useSelector((state: TState) => state.data);
  const dataInputs = useSelector((state: TState) => state.dataInputs);
  const userList = useSelector((state: TState) => state.userList);
  const currentTab = useSelector((state: TState) => state.currentTab);

  const canI = useCanI();

  const columns = useMemo(
    () =>
      formatForTable(
        keyBy(dataInputs, 'name_id'),
        userList,
        canI,
        (tab || currentTab || {}).tableColumns,
      ),
    [tab, currentTab, dataInputs, userList],
  );

  const editEntryKey = useMemo(
    () => (currentTab?.isDuplicate ? 'fixDuplicate' : 'editEntry'),
    [currentTab?._id],
  );

  const props = useTableContainer({
    data,
    columns,
    editEntryKey,
    pendingKey: 'global/async/getData/pending',
  });

  return (tab || currentTab || {})?._id ? <Table {...props} /> : null;
};

export default DynamicTable;
