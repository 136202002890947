import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  Box,
  Menu,
  MenuButton,
  Badge,
  MenuList,
  Flex,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { allIcons, CirlceIcon, NormalIcon } from '../../utils/Icon';
import { TState, useActions } from '../../../redux/store';
import Chip from '../../common/Chip';
import {
  deleteNotif,
  deleteNotifs,
  getNotifFile,
  updateNotif,
  updateNotifs,
} from '../../../api/tabsApi';
import { downloadFile } from '../../../helpers';
import { useEffect, useState } from 'react';

const notificationTypes: Record<string, string> = {
  comment: 'commentaire',
  import: 'import',
};

const notificationColors: Record<string, string> = {
  comment: 'blue.300',
  import: 'green.500',
};

const Notifications = () => {
  const notifs = useSelector((state: TState) => state.notifs);
  const theme = useSelector((state: TState) => state.theme);
  const data = useSelector((state: TState) => state.data);
  const tabs = useSelector((state: TState) => state.tabs);
  const currentTab = useSelector((state: TState) => state.currentTab);

  const { setCurrentTab, setModalType, setRowData, getDataDetail } =
    useActions();

  const [notif, setNotif] = useState<any>(null);

  const markAllAsRead = async () => {
    await updateNotifs({ read: true });
  };

  const markAsRead = async (id: string) => {
    await updateNotif(id, { read: true });
  };

  const removeNotification = async (id: string) => {
    await deleteNotif(id);
  };

  const removeAllNotifications = async () => {
    await deleteNotifs();
  };

  const goTo = (n: any) => {
    const row = data.find((r) => r._id === n.rowId);
    setRowData(row);
    getDataDetail({ rowId: n.rowId });
    setModalType('editEntry');
    setNotif(null);
  };

  const openFolder = async (notification: any) => {
    if (currentTab?._id === notification.tabId) {
      goTo(notification);
    } else {
      setNotif(notification);
      const tab = tabs.find((t) => t._id === notification.tabId);
      setCurrentTab(tab);
    }
  };

  useEffect(() => {
    if (!notif) return;
    goTo(notif);
  }, [data]);

  const downloadExport = async (notif: any) => {
    const respData = await getNotifFile(notif._id);
    await downloadFile(
      respData,
      `doublon-${moment(notif.date).format('DD/MM/YYYY')}.xlsx`,
    );
  };

  const unreadCount = notifs.filter((n) => !n.read).length;

  return (
    <Menu id='notifications'>
      <MenuButton>
        <Box position='relative'>
          <CirlceIcon as={allIcons.HiBell} mr={2} />
          {unreadCount > 0 && (
            <Chip nb={unreadCount} bgColor='red.500' color='white' />
          )}
        </Box>
      </MenuButton>
      <MenuList maxH='500px' overflowY='auto' w='400px' p={0}>
        {notifs.length > 0 && (
          <Flex
            p={2}
            justify='flex-end'
            borderBottom='1px'
            borderColor='gray.100'
            gap={2}
            fontSize={theme.fontSize.xSmall}
          >
            {!!unreadCount && (
              <Flex
                onClick={markAllAsRead}
                alignItems='center'
                cursor='pointer'
                p={1}
                borderRadius={5}
                _hover={{ bgColor: theme.lighter }}
              >
                <NormalIcon as={allIcons.HiCheck} boxSize={'15px'} />
                Tout marquer comme lu
              </Flex>
            )}
            <Flex
              onClick={removeAllNotifications}
              alignItems='center'
              cursor='pointer'
              p={1}
              borderRadius={5}
              _hover={{ bgColor: theme.lighter }}
            >
              <NormalIcon
                as={allIcons.HiMiniTrash}
                boxSize={'15px'}
                color='red.500'
              />
              Tout supprimer
            </Flex>
          </Flex>
        )}
        {notifs.length === 0 ? (
          <Box p={4} textAlign='center'>
            <Text>Aucune notification</Text>
          </Box>
        ) : (
          [...notifs].reverse().map((notification) => (
            <Box
              px={5}
              py={2}
              key={notification.id}
              borderBottom='1px'
              borderColor='gray.100'
            >
              <Flex alignItems='center' gap={2} w='100%' mt={1} mb={2}>
                <Badge
                  bgColor={notificationColors[notification.type]}
                  color={theme.none}
                  fontSize={theme.fontSize.xSmall}
                  textTransform='capitalize'
                >
                  {notificationTypes[notification.type]}
                </Badge>
                <Box flexGrow={1}>
                  {notification.sendBy && (
                    <Text
                      fontSize={theme.fontSize.xSmall}
                      fontWeight='medium'
                      color={theme.secondary}
                    >
                      Envoyé par {notification.sendBy}
                    </Text>
                  )}
                </Box>
                <Text fontSize={theme.fontSize.xSmall}>
                  {moment(notification.date).fromNow()}
                </Text>
              </Flex>
              <Flex alignItems='center'>
                {/import/gi.test(notification.type) ? (
                  <Text flexGrow={1} fontSize={theme.fontSize.small}>
                    {notification.content}
                  </Text>
                ) : (
                  <Text
                    flexGrow={1}
                    mt={1}
                    noOfLines={2}
                    fontSize={theme.fontSize.small}
                    _groupHover={{ noOfLines: undefined }}
                    transition='all 0.2s'
                    dangerouslySetInnerHTML={{ __html: notification.content }}
                  />
                )}
                {notification.tabId && (
                  <Tooltip label='Aller au dossier'>
                    <span>
                      <NormalIcon
                        as={allIcons.HiFolderOpen}
                        boxSize={'15px'}
                        onClick={(e) => {
                          e.stopPropagation();
                          openFolder(notification);
                        }}
                      />
                    </span>
                  </Tooltip>
                )}
                {notification.data && (
                  <Tooltip label="Télécharger l'export">
                    <span>
                      <NormalIcon
                        as={allIcons.HiDocumentArrowDown}
                        boxSize={'15px'}
                        onClick={(e) => {
                          e.stopPropagation();
                          downloadExport(notification);
                        }}
                      />
                    </span>
                  </Tooltip>
                )}
                {!notification.read && (
                  <Tooltip label='Marquer comme lu'>
                    <span>
                      <NormalIcon
                        as={allIcons.HiCheck}
                        boxSize={'15px'}
                        onClick={(e) => {
                          e.stopPropagation();
                          markAsRead(notification._id);
                        }}
                      />
                    </span>
                  </Tooltip>
                )}
                <Tooltip label='Supprimer la notification'>
                  <span>
                    <NormalIcon
                      as={allIcons.HiMiniTrash}
                      color='red.500'
                      boxSize={'15px'}
                      onClick={(e) => {
                        e.stopPropagation();
                        removeNotification(notification._id);
                      }}
                    />
                  </span>
                </Tooltip>
                {/* <IconButton
                  as={allIcons.trash}
                  aria-label='Supprimer la notification'
                  size='xs'
                  variant='ghost'
                  colorScheme='red'
                  onClick={(e) => {
                    e.stopPropagation();
                    removeNotification(notification.id);
                  }}
                  opacity={0}
                  _groupHover={{ opacity: 1 }}
                  transition='opacity 0.2s'
                /> */}
              </Flex>
            </Box>
          ))
        )}
      </MenuList>
    </Menu>
  );
};

export default Notifications;
