import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Text,
  Button,
  Box,
  Flex,
  Icon,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';

import { colors } from '../../theme';
import { TColumnData, TTab } from './type';
import { CirlceIcon, allIcons, icons } from '../utils/Icon';
import EditColumns from './EditColumns';
import { updateColumn, updateTabOrder } from '../../api/tabsApi';
import { TState, useActions } from '../../redux/store';
import { useSubTabs } from '../../redux/actions';
import DragDropList from '../dragAndDrop/DragDropList';

const Element = ({
  tab,
  idx,
  onClick,
  selected,
}: {
  tab: TTab;
  idx: number;
  onClick: any;
  selected: boolean;
}) => {
  const subTabs = useSubTabs();
  const isEditMode = useSelector((state: TState) => state.isEditMode);
  const { setModalType, setTabToUpdate } = useActions();

  return (
    <Box pb={3} w='100%'>
      <Flex
        p={2}
        key={tab?._id}
        textTransform='capitalize'
        onClick={() => onClick(idx)}
        borderWidth='1px'
        borderRadius={5}
        backgroundColor={selected ? `${colors.main}.300` : 'white'}
        borderColor={selected ? `${colors.main}.600` : 'white'}
        color={selected ? 'white' : 'black'}
        fontWeight={selected ? 'bold' : 'auto'}
        flexGrow={idx === 0 ? 1 : 0}
      >
        {tab?.icon && <Icon as={allIcons[tab?.icon]} boxSize={25} mr={2} />}
        <Text whiteSpace='nowrap'>
          {idx === 0 ? 'vue principale' : tab?.title}
        </Text>
        <Box flexGrow={1} />
        {idx !== 0 && isEditMode && (
          <>
            <CirlceIcon
              as={icons.edit}
              ml={3}
              onClick={() => {
                setTabToUpdate(subTabs[idx]);
                setModalType('editSubTab');
              }}
            />
            <CirlceIcon
              as={icons.cross}
              onClick={() => {
                setModalType('delTab');
              }}
            />
          </>
        )}
      </Flex>
    </Box>
  );
};

const ListItem = ({ item, idx }: any) => {
  const tabToUpdate = useSelector((state: TState) => state.tabToUpdate);
  const { setTabToUpdate } = useActions();

  return (
    <Element
      tab={item}
      idx={idx}
      onClick={() => setTabToUpdate(item)}
      selected={tabToUpdate?._id === item._id}
    />
  );
};

const SubTabsWithOrder = () => {
  const subTabs = useSubTabs();
  const currentTab = useSelector((state: TState) => state.currentTab);
  const tabToUpdate = useSelector((state: TState) => state.tabToUpdate);
  const tabTitles = useSelector((state: TState) => state.tabTitles);
  const theme = useSelector((state: TState) => state.theme);

  const { setModalType, getTabs } = useActions();

  const [hasChanges, setHasChanges] = useState(false);
  const [tableColumns, setTableColumns] = useState<TColumnData[]>(
    tabToUpdate?.tableColumns || [],
  );

  const isPlanning = currentTab?.isPlanning;
  const isDuplicate = currentTab?.isDuplicate;

  const update = async () => {
    if (!tabToUpdate) return;

    setHasChanges(false);

    await updateColumn({
      id: tabToUpdate?._id,
      data: tableColumns?.map((t) => t.name_id),
      isPlanning,
      isDuplicate,
    });

    getTabs();
  };

  const onDrop = async (list: TTab[]) => {
    await updateTabOrder(
      list.reduce(
        (prev: string[], curr: TTab, i) =>
          i === 0 ? prev : [...prev, curr._id],
        [],
      ),
    );

    getTabs();
  };

  useEffect(() => {
    setTableColumns(tabToUpdate?.tableColumns || []);
    setHasChanges(false);
  }, [tabToUpdate]);

  if (currentTab?.cloneId) {
    return (
      <>
        <Flex
          alignItems='center'
          bgColor={`${colors.main}.50`}
          w='fit-content'
          p={2}
          borderRadius={5}
        >
          Cette page est un clone de la page
          <Text
            textTransform='capitalize'
            fontSize='1.1em'
            fontWeight='500'
            ml={1}
          >
            {tabTitles[currentTab?.cloneId] || ''}
          </Text>
          , aucune modification est possible excepté les filtres
        </Flex>
        {!isPlanning && (
          <Button
            mt={2}
            onClick={() => {
              setModalType('editFilters');
            }}
            backgroundColor={`${colors.main}.300`}
            color='white'
          >
            Editer les filtres
          </Button>
        )}
      </>
    );
  }

  return !tabToUpdate ? null : (
    <Flex position='relative' flexDirection='column' h='100%'>
      <Flex alignItems='center' pb={2}>
        <Box flexGrow={1} />
        <Button
          onClick={() => {
            setModalType('addSubTab');
          }}
          ml={3}
          backgroundColor={`${colors.main}.300`}
          color='white'
        >
          Ajouter un onglet
        </Button>
        {!isPlanning && (
          <Button
            ml={2}
            onClick={() => {
              setModalType('editFilters');
            }}
            backgroundColor={`${colors.main}.300`}
            color='white'
          >
            Editer les filtres
          </Button>
        )}
        <CirlceIcon
          as={allIcons.HiPlusSmall}
          onClick={() => setModalType('addInput')}
          mx={3}
        />
        <Button
          onClick={update}
          backgroundColor={`${colors.main}.500`}
          color='white'
        >
          Sauvegarder
        </Button>
        {hasChanges && (
          <Box flexGrow={0} ml={3}>
            <Alert status='warning' p={2} borderRadius={10}>
              <AlertIcon />
              Pensez à sauvegarder
            </Alert>
          </Box>
        )}
      </Flex>
      <Flex id='subTabsPanels' overflow='hidden'>
        <Box w={220}>
          <DragDropList
            noDragIndexes={[0]}
            listItem={subTabs}
            onDrop={onDrop}
            List={ListItem}
            type='SUBTAB'
          />
        </Box>
        <Box
          flexGrow={1}
          borderWidth='2px'
          overflow='auto'
          p={7}
          ml={3}
          bgColor={theme.none}
        >
          {tabToUpdate && (
            <EditColumns
              tab={tabToUpdate as TTab}
              tableColumns={tableColumns}
              setTableColumns={setTableColumns}
              setHasChanges={setHasChanges}
            />
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

export default SubTabsWithOrder;
