import { Flex } from '@chakra-ui/react';
import { SpinnerIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';
import { TState } from '../../redux/store';

const Loader = ({ color = 'currentColor' }) => {
  const theme = useSelector((state: TState) => state.theme);

  return (
    <Flex
      boxSize='80px'
      position='fixed'
      top='calc(50% - 40px)'
      left='calc(50% - 40px)'
      backgroundColor='#FFF'
      borderRadius='15px'
      justifyContent='center'
      alignItems='center'
      zIndex={1}
    >
      <SpinnerIcon
        boxSize='40px'
        color={`${theme.dark} !important`}
        style={{
          animation: 'spin .5s linear infinite',
          color,
        }}
      />
    </Flex>
  );
};

export default Loader;
