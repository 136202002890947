import { isArray, mergeWith } from 'lodash';
import moment from 'moment';
import * as XLSX from 'xlsx';

export const isEmpty = (val: any) => val == null || val === '';

export const mergeWithArray = (object: any, other: any) => {
  const customizer = (objValue: any, srcValue: any) => {
    if (isArray(objValue)) {
      return objValue.concat(srcValue);
    }
  };

  return mergeWith(object, other, customizer);
};

export const getRandomVibrantColor = (): string => {
  const hue = Math.floor(Math.random() * 360);
  const saturation = Math.floor(Math.random() * 30) + 70; // Saturation between 70% and 100%
  const lightness = Math.floor(Math.random() * 20) + 50; // Lightness between 50% and 70%
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

export const getValue = (el: string, attr: string) => {
  const element: any = document.querySelector(el);
  const nb =
    element &&
    Number(window.getComputedStyle(element)[attr as any]?.replace('px', ''));

  return /top|bottom/.test(attr)
    ? element?.getBoundingClientRect()[attr]
    : isNaN(nb)
    ? window.getComputedStyle(element)[attr as any]
    : nb;
};

export const getHeight = (
  elId: string,
  elAttr: string,
  mainEl: string,
  attribute: string,
) => {
  const value1 = getValue(elId, elAttr);
  const value2 = getValue(mainEl, attribute);

  return value1 + value2;
};

export const getRandom = (array: any[]) =>
  array[Math.floor(Math.random() * array.length)];

export const getReadableColor = (hexColor?: string) => {
  if (!hexColor) return 'black';

  // Remove the leading hash if present
  hexColor = hexColor.replace(/^#/, '');

  // Parse the hex color
  const bigint = parseInt(hexColor, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  // Calculate the brightness of the color
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  // Return 'black' for light colors and 'white' for dark colors
  return brightness > 128 ? 'black' : 'white';
};

export const formatDate = (dateStr: string, us?: boolean, format?: string) =>
  moment(`${dateStr}`).isValid()
    ? moment(`${dateStr}`).format(format || (us ? 'YYYY-MM-DD' : 'DD/MM/YYYY'))
    : '-';

export const formatPhoneNumber = (phoneNumber: string, spaces?: boolean) => {
  // Supprimer tous les caractères non numériques
  let cleaned = ('' + phoneNumber).replace(/\D/g, '');

  // Vérifier si le numéro commence par '33' ou '+33' et le remplacer par '0'
  if (cleaned.startsWith('33')) {
    cleaned = '0' + cleaned.slice(2);
  } else if (cleaned.startsWith('+33')) {
    cleaned = '0' + cleaned.slice(3);
  } else if (cleaned.length === 9 && !cleaned.startsWith('0')) {
    // Ajouter un '0' au début si le numéro est court et ne commence pas par '0'
    cleaned = '0' + cleaned;
  }

  // Formater le numéro en groupes de deux chiffres
  const formatted = cleaned.replace(/(\d{2})(?=\d)/g, '$1 ').trim();

  return spaces ? formatted : cleaned;
};

export const strNomalize = (str: string = '') => {
  if (str == null) return '';

  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim()
    .replace(/ |'/g, '_')
    .replace(/\./g, '')
    .toLowerCase();
};

export const exportToExcel = (jsonData: any, filename = 'export_doublons') => {
  const workBook = XLSX.utils.book_new();
  const workSheet = XLSX.utils.json_to_sheet(jsonData);

  XLSX.utils.book_append_sheet(workBook, workSheet, '');
  XLSX.writeFile(workBook, `${filename}.xlsx`);
};

export const addressFormat = (addr: any) =>
  `${addr.street || ''}${
    addr.street && (addr.city || addr.postal) ? ', ' : ''
  }${addr.city || ''} ${addr.postal || ''}`.trim();

export const indexOfObject = (
  mainArr: Record<string, any>[],
  key: string,
  searchStr: string | number,
) => {
  return mainArr
    .map((i) => `${i[key]}`.toLowerCase())
    .indexOf(`${searchStr}`.toLowerCase());
};

export const regCompare = (
  pattern: string,
  text: string = '',
  options?: string,
) => {
  const regex = new RegExp(strNomalize(pattern), options);
  return regex.test(strNomalize(text));
};

export const updateFavicon = (newFavicon: string) => {
  const link: any =
    document.querySelector("link[rel*='icon']") ||
    document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = `${newFavicon}.ico`;
  document.head.appendChild(link);

  const title: any = document.querySelector('head title');
  title.textContent = newFavicon.toUpperCase();
};

export const downloadFile = async (
  data: any,
  filename = 'files',
  isUrl = false,
) => {
  try {
    const url = isUrl ? data : window.URL.createObjectURL(new Blob([data]));

    // Create an invisible anchor element to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.download = `${filename}`; // Set the file name
    document.body.appendChild(link);
    link.click(); // Trigger the download
    link.remove(); // Clean up after the download
  } catch (error) {
    console.error('Error downloading the zip:', error);
  }
};

export const htmlToText = (val: string) => {
  const textArea = document.createElement('div');
  textArea.innerHTML = val;
  return textArea.textContent;
};
