import { useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  InputGroup,
  Table,
  Tbody,
  Td,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { CirlceIcon, allIcons } from '../utils/Icon';
import UploadComponent from '../import/UploadComponent';
import { TState } from '../../redux/store';
import { downloadDocuments } from '../../api/tabsApi';
import { downloadFile } from '../../helpers';
import { useCanI } from '../../redux/actions';

const Group = ({ d, onUpdate }: { d: any; onUpdate: any }) => {
  const filesComms = useSelector((state: TState) => state.filesComms);
  const rowData = useSelector((state: TState) => state.rowData);

  const ref = useRef<any>();

  return (
    <InputGroup w='100%'>
      <Textarea
        minH='50px'
        ref={ref}
        defaultValue={filesComms[d._id]}
        onBlur={() => {
          onUpdate({
            _id: rowData._id,
            [`${d._id}-comment`]: ref.current.value,
          });
        }}
      />
    </InputGroup>
  );
};

const DocumentTable = ({
  data,
  groupFiles,
  onView,
  onUpload,
  onUpdate,
}: any) => {
  const filesStatus = useSelector((state: TState) => state.filesStatus);
  const rowData = useSelector((state: TState) => state.rowData);
  const canI = useCanI();

  const handleDownload = async (docId: string, name: string) => {
    const respData = await downloadDocuments(docId, rowData._id);
    await downloadFile(respData, `${name}.zip`);
  };

  const columns = {
    comment: false,
    validate: false,
    read: false,
    add: false,
    download: false,
  };

  const filteredData = data.filter((d: any) => {
    const canComment = canI(d._id, 'comment');
    const canValidate = canI(d._id, 'validate');
    const canAdd = canI(d._id, 'add');
    const canRead = canI(d._id, 'read') || canI(d._id, 'delete');
    const canDownload = canI(d._id, 'download');

    if (canComment) columns.comment = true;
    if (canValidate) columns.validate = true;
    if (canRead) columns.read = true;
    if (canAdd) columns.add = true;
    if (canDownload) columns.download = true;

    return canComment || canValidate || canAdd || canRead || canDownload;
  });

  return (
    <Table size='sm'>
      <Thead>
        <Tr>
          <Th w='200px'>Nom</Th>
          {columns.comment && <Th>Commentaire</Th>}
          {columns.validate && <Th w='50px'>Complet</Th>}
          {columns.read && <Th w='50px'>Voir</Th>}
          {columns.add && (
            <Th w='100px' textAlign='center'>
              Ajouter
            </Th>
          )}
          {columns.download && (
            <Th w='100px' textAlign='center'>
              Télécharger
            </Th>
          )}
        </Tr>
      </Thead>
      <Tbody>
        {filteredData.map((d: any) => {
          const canComment = canI(d._id, 'comment');
          const canValidate = canI(d._id, 'validate');
          const canAdd = canI(d._id, 'add');
          const canRead = canI(d._id, 'read') || canI(d._id, 'delete');
          const canDownload = canI(d._id, 'download');

          return (
            <Tr>
              <Td whiteSpace='nowrap'>
                <Tooltip label={d.description} placement='bottom-start'>
                  {d.name}
                </Tooltip>
              </Td>
              {columns.comment && (
                <Td>{canComment && <Group d={d} onUpdate={onUpdate} />}</Td>
              )}
              {columns.validate && (
                <Td>
                  {canValidate && (
                    <CirlceIcon
                      as={allIcons.HiCheck}
                      backgroundColor={filesStatus[d._id] ? 'green.400' : ''}
                      color={filesStatus[d._id] ? 'white' : ''}
                      onClick={() => {
                        onUpdate({
                          _id: rowData._id,
                          [`${d._id}-isDone`]: !filesStatus[d._id],
                        });
                      }}
                    />
                  )}
                </Td>
              )}
              {columns.read && (
                <Td>
                  {canRead && groupFiles[d._id]?.length ? (
                    <Box position='relative' w='fit-content'>
                      <CirlceIcon
                        as={allIcons.HiEye}
                        mr={2}
                        onClick={() => onView(d._id)}
                      />
                      <Box position='absolute' top={0} right={0}>
                        {groupFiles[d._id]?.length}
                      </Box>
                    </Box>
                  ) : null}
                </Td>
              )}
              {columns.add && (
                <Td>
                  {canAdd && (
                    <UploadComponent
                      multiple
                      onUpload={(file) => onUpload(d._id, file)}
                    />
                  )}
                </Td>
              )}
              {columns.download && (
                <Td>
                  {canDownload && groupFiles[d._id]?.length && (
                    <Box>
                      <CirlceIcon
                        as={allIcons.HiMiniArrowDownOnSquareStack}
                        onClick={() => handleDownload(d._id, d.name)}
                      />
                    </Box>
                  )}
                </Td>
              )}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default DocumentTable;
