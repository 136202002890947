import { useSelector } from 'react-redux';
import { TState } from './store';
import { cloneDeep, concat, keyBy, keys } from 'lodash';
import { TColumnData, TInput, TTab } from '../components/tabs/type';
import { useEffect, useState } from 'react';
import { TUser } from '../components/login/Login';

export const useSubTabs = () => {
  const planningTab = useSelector((state: TState) => state.planningTab);
  const duplicateTab = useSelector((state: TState) => state.duplicateTab);
  const currentTab = useSelector((state: TState) => state.currentTab);

  const [state, setState] = useState<TTab[]>([]);

  useEffect(() => {
    setState(
      (currentTab?._id === 'planning' && keys(planningTab).length
        ? concat(planningTab || [], planningTab?.childs || [])
        : currentTab?._id === 'duplicate' && keys(duplicateTab).length
        ? concat(duplicateTab || [], duplicateTab?.childs || [])
        : concat(currentTab || [], currentTab?.childs || [])) as TTab[],
    );
  }, [currentTab]);

  return state;
};

export const useAllTabs = () => {
  const planningTab = useSelector((state: TState) => state.planningTab);
  const duplicateTab = useSelector((state: TState) => state.duplicateTab);
  const tabs = useSelector((state: TState) => state.tabs);

  const [state, setState] = useState<Record<string, TTab>>({});

  useEffect(() => {
    setState(
      keyBy(
        concat(
          planningTab || [],
          duplicateTab || [],
          tabs || [],
          ...tabs.flatMap((tab) => tab.menuChilds || []),
          {
            _id: 'settings',
            title: 'Paramètres',
            isSetting: true,
            tableColumns: [] as TColumnData[],
          } as TTab,
        ),
        '_id',
      ),
    );
  }, [tabs]);

  return state;
};

export const useCanI = () => {
  const isSuper = useSelector((state: TState) => state.isSuper);
  const isAdmin = useSelector((state: TState) => state.isAdmin);
  const userRole = useSelector((state: TState) => state.userRole);

  return (key: string, action: string) =>
    isSuper || isAdmin || userRole?.[key]?.includes(action);
};

export const useGetSelect = () => {
  const dataInputs = useSelector((state: TState) => state.dataInputs);

  const [state, setState] = useState<Record<string, TInput>>(
    keyBy(dataInputs, 'name_id'),
  );

  useEffect(() => {
    setState(keyBy(dataInputs, 'name_id'));
  }, [dataInputs]);

  return (name_id: string) => {
    const input = cloneDeep(state[name_id]);

    if (!input) {
      return null;
    }

    return input;
  };
};

export const useGetUser = () => {
  const userList = useSelector((state: TState) => state.userList);

  const [state, setState] = useState<Record<string, TUser>>(
    keyBy(userList, '_id'),
  );

  useEffect(() => {
    setState(keyBy(userList, '_id'));
  }, [userList]);

  return (userId: string) => {
    const input = cloneDeep(state[userId]);

    if (!input) {
      return null;
    }

    return input;
  };
};
