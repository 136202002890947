import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  DrawerFooter,
  Flex,
  Box,
  Icon,
  Text,
} from '@chakra-ui/react';
import { allIcons, CirlceIcon, icons } from '../utils/Icon';
import { TState, useActions } from '../../redux/store';

type TModal = {
  children?: ReactNode;
  options: Record<
    string,
    {
      component?: any;
      action?: any;
      canDelete?: string;
      title: string;
      actionLabel?: string;
      pending?: boolean;
      hint?: string;
    }
  >;
};

export const ModalCrm = ({ children, options }: TModal) => {
  const isOpen = useSelector((state: TState) => state.isOpen);
  const modalType = useSelector((state: TState) => state.modalType);
  const theme = useSelector((state: TState) => state.theme);

  const { onClose, setModalType } = useActions();

  const { action, title, actionLabel, component, canDelete, pending, hint } =
    options?.[modalType] || {};

  return !options?.[modalType] ? null : (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount={false}
      closeOnOverlayClick={false}
    >
      {/* <ModalOverlay /> */}
      <DrawerContent
        flexDirection='column'
        maxW={
          /displayDuplicate|computeBeforeUpload|addToPlanning|updatePlanning/i.test(
            modalType,
          )
            ? '100%'
            : { sm: '100%', lg: '70%' }
        }
        boxShadow='dark-lg'
      >
        <DrawerHeader h='62px'>{title}</DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody py={0} flexGrow={1}>
          {component || children}
        </DrawerBody>

        <DrawerFooter alignItems='center' id='drawerFooter' h='62px'>
          {canDelete && (
            <CirlceIcon
              backgroundColor='red.500'
              color='white'
              cursor='pointer'
              _hover={{
                backgroundColor: 'white',
                color: 'red.500',
                fontSize: '1.5rem',
              }}
              icon={icons.trash}
              onClick={() => setModalType(canDelete)}
            />
          )}
          <Box flexGrow={1} />
          {(hint || /addEntry|editEntry/i.test(modalType)) && (
            <Flex alignItems='center' mx={5}>
              {/addEntry|editEntry/i.test(modalType) && (
                <Icon
                  as={allIcons.HiMiniStar}
                  boxSize={3}
                  mr={1}
                  color='orange.400'
                />
              )}
              <Text fontStyle='italic' fontSize={theme.fontSize.xSmall}>
                {hint || 'Champs importants pour la complétion du dossier'}
              </Text>
            </Flex>
          )}
          {action && (
            <Button
              backgroundColor={theme.normal}
              color={theme.none}
              onClick={action}
              mr={3}
              isLoading={pending}
            >
              {actionLabel || 'Valider'}
            </Button>
          )}
          {/* <Button colorScheme="blue" onClick={onClose}>
						Cancel
					</Button> */}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default ModalCrm;
