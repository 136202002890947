import { useState } from 'react';
import { useSelector } from 'react-redux';
import { flatMap } from 'lodash';
import { IconType } from 'react-icons';
import {
  Text,
  Flex,
  Tag,
  Icon,
  Box,
  Collapse,
  useDisclosure,
} from '@chakra-ui/react';
import { allIcons, icons } from '../utils/Icon';
import { TAction } from './Form';
import { colors } from '../../theme';
import { TState } from '../../redux/store';
import { useGetSelect } from '../../redux/actions';
import { regCompare } from '../../helpers';
import RCheckbox from './RCheckbox';

export type TLeaf = {
  name: string;
  id: any;
  type?: string;
  childs?: TLeaf[];
  icon?: IconType | string;
  noSpread?: boolean;
};

type TLeafProps = {
  leafs: TLeaf[];
  selectedValues: Record<string, boolean>;
  onChange: (
    checkId: string,
    value: boolean,
    action: string,
    childs?: TLeaf[],
    noSpread?: boolean,
  ) => void;
  idx?: number;
  actions?: TAction[];
  parentIdProp?: any;
  filter?: string;
  forcedAction?: boolean;
};

const Leafs = (props: TLeafProps) => {
  return (
    <>
      {props.leafs.map((leaf, leafIdx) => (
        <Leaf {...props} {...leaf} leafIdx={leafIdx} />
      ))}
    </>
  );
};

const Leaf = ({
  onChange,
  idx: idxProp = 0,
  actions,
  parentIdProp,
  name,
  id,
  childs,
  noSpread,
  type,
  icon,
  selectedValues,
  filter,
  forcedAction,
}: TLeafProps & TLeaf & { leafIdx: number }) => {
  const theme = useSelector((state: TState) => state.theme);
  const selectById = useGetSelect();

  const { isOpen, onToggle } = useDisclosure();

  const parentId = idxProp === 0 ? id : parentIdProp;
  const leafName = selectById(name)?.label || name;

  const allChilds = flatMap(childs, (c) => [c, ...(c.childs || [])]).filter(
    (f) => f,
  );

  const hasChild =
    !filter ||
    allChilds.filter((child) => regCompare(filter, child.name, 'ig'))?.length
      ? !!childs?.length
      : false;

  const isNoCollapse = isOpen || !!filter;
  const showCurrent = !filter || regCompare(`${filter}`, leafName, 'ig');

  return (
    <>
      {(hasChild || showCurrent) && (
        <Flex borderBottomWidth={1} fontSize={theme.fontSize.small}>
          <Flex
            mr={3}
            flexGrow={1}
            backgroundColor={childs ? `${colors.main}.50` : ''}
            borderRadius={15}
            pl={3}
            my={1}
            _hover={{
              backgroundColor: childs ? `${colors.main}.100` : '',
            }}
            cursor={childs ? 'pointer' : 'auto'}
          >
            <Flex
              w='100%'
              p={0}
              alignItems='center'
              _hover={{ backgroundColor: 'none' }}
              onClick={() => {
                if (!childs) return;
                onToggle();
              }}
            >
              <Icon
                boxSize={7}
                as={
                  allIcons[`${icon}`] ||
                  icon ||
                  (childs ? icons.page : icons.column)
                }
              />
              <Text p={2} textTransform='capitalize'>
                {leafName}
                {type === 'address' ? ' adresse' : ''}
              </Text>
              <Box flexGrow={1} />
              {childs && (
                <Icon
                  boxSize={5}
                  as={allIcons[`HiChevron${isNoCollapse ? 'Down' : 'Up'}`]}
                  mr={3}
                />
              )}
            </Flex>
          </Flex>
          <Flex>
            {actions?.map(({ level, title, ...action }) => {
              if (level !== undefined && level !== idxProp) return null;

              const filteredAllChilds = allChilds?.filter(
                (c) => selectedValues[`${c?.id}-${title}`],
              );

              return (
                <LeafAction
                  {...action}
                  isIndeterminate={
                    !noSpread &&
                    filteredAllChilds.length > 0 &&
                    filteredAllChilds.length < allChilds.length
                  }
                  isChecked={
                    (!noSpread &&
                      !!allChilds.length &&
                      filteredAllChilds.length === allChilds.length) ||
                    selectedValues[`${id}-${title}`]
                  }
                  onChange={(value: boolean) => {
                    onChange(
                      `${id}-${title}`,
                      value,
                      title || '',
                      childs,
                      noSpread,
                    );
                  }}
                  forcedAction={forcedAction}
                />
              );
            })}
          </Flex>
        </Flex>
      )}
      {childs && (
        <Box pl={3}>
          <Collapse in={isNoCollapse} animateOpacity>
            {isNoCollapse && (
              <Leafs
                leafs={childs}
                onChange={onChange}
                idx={idxProp + 1}
                actions={actions}
                parentIdProp={parentId}
                selectedValues={selectedValues}
                filter={filter}
                forcedAction={forcedAction}
              />
            )}
          </Collapse>
        </Box>
      )}
    </>
  );
};

const LeafAction = ({
  onChange,
  label,
  isChecked,
  isIndeterminate,
  forcedAction,
}: TAction) => {
  const theme = useSelector((state: TState) => state.theme);

  return (
    <Tag m={2} p={2}>
      <RCheckbox
        isIndeterminate={isIndeterminate}
        isChecked={isChecked}
        onChange={(e) => {
          onChange?.(e);
        }}
        w='auto'
        isForced={forcedAction}
      >
        <Text fontSize={theme.fontSize.xSmall}>{label}</Text>
      </RCheckbox>
    </Tag>
  );
};

type TTreeInput = {
  list: TLeaf[];
  actions: TAction[];
  selected?: any;
  filter?: string;
  forcedAction?: boolean;
  onChange: (e: any) => void;
};

const childCheck = (
  childs: TLeaf[],
  action: string,
  final: any,
  value: boolean,
) => {
  childs?.forEach((child) => {
    final[`${child.id}-${action}`] = value;
    child.childs && childCheck(child.childs, action, final, value);
  });
};

const TreeInput = ({
  list,
  actions,
  selected = {},
  filter,
  forcedAction,
  onChange,
}: TTreeInput) => {
  const [selectedValues, setSelectedValues] =
    useState<Record<string, any>>(selected);

  const toggleTree = (
    checkId: string,
    value: boolean,
    action: string,
    childs?: TLeaf[],
    noSpread?: boolean,
  ) => {
    const newValues = { ...selectedValues };

    newValues[checkId] = value;

    if (!noSpread) {
      childs?.length && childCheck(childs, action, newValues, value);
    }

    setSelectedValues(newValues);
    onChange(newValues);
  };

  return (
    <Leafs
      leafs={list}
      onChange={toggleTree}
      actions={actions}
      selectedValues={selectedValues}
      filter={filter}
      forcedAction={forcedAction}
    />
  );
};

export default TreeInput;
