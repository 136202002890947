import { useState } from 'react';
import { concat, entries, values } from 'lodash';
import {
  Badge,
  Flex,
  Icon,
  Input,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { allIcons } from '../utils/Icon';
import moment from 'moment';

const translate: Record<string, string> = {
  documentId: 'Documents',
};

const HistoryTable = ({ data }: any) => {
  const [historyFilter, setHistoryFilter] = useState('');

  const reducedData = data?.reduce(
    (prev: any, { diff, ...d }: any) =>
      concat(
        prev,
        entries(diff).flatMap(([k, [oldValue, newValue]]: any) => ({
          ...d,
          key: k,
          action:
            d.action ||
            (d.isDelete
              ? 'Supprimé'
              : d.isDocument
              ? 'Ajouté'
              : d.isCreate
              ? 'Créé'
              : 'Modifié'),
          oldValue,
          newValue,
        })),
      ),
    [],
  );

  const filteredData =
    reducedData?.filter((rd: any) =>
      values(rd).join(' ').toLowerCase().includes(historyFilter.toLowerCase()),
    ) || [];

  return (
    <>
      <Input
        placeholder='Rechercher un champ'
        mb={2}
        onChange={(e) => setHistoryFilter(e.target.value)}
        value={historyFilter}
      />
      <Table size='sm'>
        <Thead>
          <Tr>
            <Th flexDirection='column'>
              <Flex>
                <Icon as={allIcons.HiFolderOpen} />
                <Icon as={allIcons.HiCalendarDays} />
                <Icon as={allIcons.HiDocumentText} />
              </Flex>
            </Th>
            <Th>Date</Th>
            <Th>Action</Th>
            <Th>Par</Th>
            <Th>Nom</Th>
            <Th>Avant</Th>
            <Th>Après</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredData.reverse().map((d: any) => (
            <Tr whiteSpace='nowrap'>
              <Td>
                <Icon
                  as={
                    allIcons[
                      d.isDocument
                        ? 'HiDocumentText'
                        : d.isPlanning
                        ? 'HiCalendarDays'
                        : 'HiFolderOpen'
                    ]
                  }
                />
              </Td>
              <Td>{moment(d.date).format('DD/MM/YYYY HH:mm')}</Td>
              <Td>{d.action}</Td>
              <Td>{d.user}</Td>
              <Td>{translate[`${d.key}`] || d.key}</Td>
              <Td>
                <Badge
                  borderRadius={5}
                  backgroundColor='red.100'
                  textTransform='none'
                >
                  {(/string|number/.test(typeof d.oldValue)
                    ? d.oldValue
                    : d.oldValue?.value) ?? '-'}
                </Badge>
              </Td>
              <Td>
                <Badge
                  borderRadius={5}
                  backgroundColor='green.100'
                  textTransform='none'
                >
                  {(/string|number/.test(typeof d.newValue)
                    ? d.newValue
                    : d.newValue?.value) ?? '-'}
                </Badge>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};

export default HistoryTable;
