import { useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Text,
  Box,
  Flex,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Input,
  Button,
  Icon,
  useDisclosure,
} from '@chakra-ui/react';
import { TColumnData, TTab } from './type';
import { groupBy, keyBy, keys, orderBy } from 'lodash';
import { CirlceIcon, allIcons } from '../utils/Icon';
import { colors } from '../../theme';
import { TState } from '../../redux/store';
import DragDropList from '../dragAndDrop/DragDropList';

const Element = ({
  col,
  handleChange,
}: {
  col: TColumnData;
  handleChange: (item: TColumnData) => void;
}) => {
  const theme = useSelector((state: TState) => state.theme);

  return (
    <Box pb={3}>
      <Flex
        borderRadius={5}
        p={1}
        alignItems='center'
        fontWeight={col.type === 'titre' ? 'bold' : ''}
        border='1px solid'
        borderColor={theme.dark}
        color={theme.darker}
      >
        <Text
          textTransform='uppercase'
          mr={2}
          fontWeight={col.type === 'title' ? 'bold' : ''}
        >
          {col.label}
        </Text>
        {col.type !== 'title' && `(ID: ${col.name_id}, TYPE: ${col.type})`}
        <Box flexGrow={1} />
        <CirlceIcon
          ml={3}
          as={allIcons.HiMiniXMark}
          onClick={() => handleChange(col)}
        />
      </Flex>
    </Box>
  );
};

type TEditColumns = {
  tab: TTab;
  tableColumns: TColumnData[];
  setTableColumns: React.Dispatch<React.SetStateAction<TColumnData[]>>;
  setHasChanges: React.Dispatch<React.SetStateAction<boolean>>;
};

const ToggleButton = ({ label }: { label?: string }) => {
  const { isOpen, onToggle } = useDisclosure();
  const theme = useSelector((state: TState) => state.theme);

  return (
    <AccordionButton textTransform='capitalize' textAlign='left'>
      <Text flexGrow={1}>{label || 'Champs de base'}</Text>
      <Icon
        onClick={onToggle}
        color={theme.dark}
        as={isOpen ? allIcons.HiChevronUp : allIcons.HiChevronDown}
        boxSize={15}
      />
    </AccordionButton>
  );
};

const EditColumns = ({
  tableColumns,
  setTableColumns,
  setHasChanges,
}: TEditColumns) => {
  const dataInputs = useSelector((state: TState) => state.dataInputs);
  const inputRef = useRef<HTMLInputElement>(null);

  const activeInput = keyBy(tableColumns, 'name_id');
  const inputs = dataInputs?.filter(
    (col) => !activeInput[`${col.name_id}`] && col.name_id !== 'planning_type',
  );
  const inputsByGroup = groupBy(inputs, ({ group }) => (!group ? '' : group));
  const orderedKeys = orderBy(keys(inputsByGroup));

  const handleChange = (col: TColumnData) => {
    setHasChanges(true);
    setTableColumns((c) => c.filter((f) => f.name_id !== col.name_id));
  };

  return (
    <Flex>
      <Box
        flexBasis='50%'
        borderRight='2px solid'
        borderColor='gray.300'
        pr={7}
      >
        <Box textAlign='center'>
          <Text fontSize={26} fontWeight={500}>
            Liste des champs
          </Text>
          <Text mb={3}>(Cliquer pour ajouter)</Text>
        </Box>
        <Accordion allowMultiple>
          {orderedKeys.map((groupName) => (
            <AccordionItem>
              <ToggleButton label={groupName || 'Champs de base'} />
              <AccordionPanel>
                {inputsByGroup[groupName].map((col) => (
                  <Flex
                    key={col._id}
                    borderRadius={5}
                    w='fit-content'
                    onClick={() => {
                      setHasChanges(true);
                      setTableColumns((c) => [...c, col]);
                    }}
                    cursor='pointer'
                    p={1}
                    _hover={{ backgroundColor: `${colors.main}.100` }}
                  >
                    <Text textTransform='uppercase' mr={2}>
                      {col.label}
                    </Text>
                    ({col.name_id})
                  </Flex>
                ))}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Box>
      <Box flexGrow={1} ml={7}>
        <Box textAlign='center'>
          <Text fontSize={26} fontWeight={500}>
            Champs ajoutés
          </Text>
          <Text mb={3}>(Déplacer pour changer l'ordre)</Text>
        </Box>
        <Flex mx={3} mb={3}>
          <Input ref={inputRef} mr={3} placeholder='Ajouter un titre' />
          <Button
            onClick={() => {
              setTableColumns((tc) => [
                {
                  label: inputRef.current?.value || '',
                  type: 'title',
                  name_id: `title_${inputRef.current?.value || ''}`,
                },
                ...tc,
              ]);
            }}
          >
            +
          </Button>
        </Flex>
        <DragDropList
          listItem={tableColumns}
          onDrop={(list) => {
            setHasChanges(true);
            setTableColumns(list);
          }}
          List={({ item }) => (
            <Element col={item} handleChange={handleChange} />
          )}
          type='TABLE_COLUMNS'
        />
      </Box>
    </Flex>
  );
};

export default EditColumns;
