import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAppointments } from '../api/planningApi';
import {
  fetchTabs,
  fetchInputs,
  fetchDocumentList,
  fetchDataCount,
  fetchData,
  fetchDataDetail,
  getRoles,
  getUsers,
  getUserRole,
  fetchNotifs,
  fetchRowData,
} from '../api/tabsApi';
import { actions, TState } from './store';

export const fetchAppointments = createAsyncThunk(
  'global/async/fetchAppointments',
  async (_, thunkAPI: any) => {
    const state = thunkAPI.getState();
    const resp = await getAppointments(state.calendarCurrentDay);
    return { appointments: resp.data };
  },
);

export const getTabs = createAsyncThunk('global/async/getTabs', async () => {
  const resp = await fetchTabs();
  return {
    tabs: resp?.data || [],
    tabTitles: resp?.tabsById || [],
    settingsTabs: resp?.customTabs || [],
    planningTab: resp?.planningTab || [],
    duplicateTab: resp?.duplicateTab || [],
  };
});

export const getInputs = createAsyncThunk(
  'global/async/getInputs',
  async (_, thunkAPI: any) => {
    const state = thunkAPI.getState();

    if (state.pendings['global/async/getInputs/pending'] > 1) {
      return;
    }

    const resp = await fetchInputs();
    return { dataInputs: resp?.data || [] };
  },
);

export const getDocumentList = createAsyncThunk(
  'global/async/getDocumentList',
  async (_, thunkAPI: any) => {
    const state = thunkAPI.getState();

    if (state.pendings['global/async/getDocumentList/pending'] > 1) {
      return;
    }

    const resp = await fetchDocumentList();
    return { documentList: resp?.data || [] };
  },
);

export const getDataCount = createAsyncThunk(
  'global/async/getDataCount',
  async (_, thunkAPI: any) => {
    const state = thunkAPI.getState();

    if (state.pendings['global/async/getDataCount/pending'] > 1) {
      return;
    }

    const respCount = await fetchDataCount();
    return { dataCount: respCount.data };
  },
);

export const getData = createAsyncThunk(
  'global/async/getData',
  async (
    { tabId, rowId }: { tabId: string; rowId: string } = {} as any,
    thunkAPI: any,
  ) => {
    const state: TState = thunkAPI.getState();
    const pageId = state.currentTab?._id;

    if (tabId && rowId) {
      const resp = await fetchRowData(tabId, rowId);
      return resp.data;
    }

    if (!pageId) return;
    if (state.currentTab?.isSetting || state.currentTab?.isPlanning) return;

    const hasInput = state.dataInputs?.length;
    const hasUsers = state.userList?.length;

    if (hasInput && hasUsers) {
      const resp = await fetchData(pageId);

      thunkAPI.dispatch(actions.setRowSelected([]));
      thunkAPI.dispatch(getDataCount());
      thunkAPI.dispatch(actions.setNoFilter(!!resp.noFilter));

      thunkAPI.dispatch(actions.setData(resp.data || []));
    } else {
      setTimeout(() => {
        thunkAPI.dispatch(getData());
      }, 100);
    }
  },
);

export const getDataDetail = createAsyncThunk(
  'global/async/getDataDetail',
  async ({ rowId }: { rowId: string }, thunkAPI: any) => {
    const state: TState = thunkAPI.getState();

    if (!rowId) return;
    if (state.currentTab?.isSetting) return;

    const resp = await fetchDataDetail(rowId);

    thunkAPI.dispatch(actions.setAppointsHistory(resp?.appoints));
    thunkAPI.dispatch(actions.setRowHistory(resp?.history));
    thunkAPI.dispatch(actions.setFilesStatus(resp?.statuses));
    thunkAPI.dispatch(actions.setFilesComms(resp?.comms));
  },
);

export const getUserRoleContext = createAsyncThunk(
  'global/async/getUserRoleContext',
  async (_, thunkAPI: any) => {
    const resp = await getUserRole();

    thunkAPI.dispatch(actions.setUserRole(resp.data || []));
    thunkAPI.dispatch(actions.setIsAdmin(!!resp.isAdmin));
    thunkAPI.dispatch(actions.setIsSuper(!!resp.isSuper));
    thunkAPI.dispatch(actions.setIsPlanning(!!resp.isPlanning));
    thunkAPI.dispatch(actions.setIsDuplicate(!!resp.isDuplicate));
    thunkAPI.dispatch(actions.setLogin(resp.login));
    thunkAPI.dispatch(actions.setUser(resp));
  },
);

export const getRolesList = createAsyncThunk(
  'global/async/getRolesList',
  async (_, thunkAPI: any) => {
    const state = thunkAPI.getState();

    if (state.pendings['global/async/getRolesList/pending'] > 1) {
      return;
    }

    const resp = await getRoles();
    return {
      roleList: resp.data || [],
    };
  },
);

export const getUsersList = createAsyncThunk(
  'global/async/getUsersList',
  async (_, thunkAPI: any) => {
    const state = thunkAPI.getState();

    if (state.pendings['global/async/getUsersList/pending'] > 1) {
      return;
    }

    const resp = await getUsers();
    return {
      userList: resp.data || [],
    };
  },
);

export const getNotifs = createAsyncThunk(
  'global/async/getNotifs',
  async () => {
    const resp = await fetchNotifs();
    return { notifs: resp?.data || [] };
  },
);
