import { Checkbox, CheckboxProps } from '@chakra-ui/react';

const RCheckbox = ({
  isForced,
  isChecked,
  children,
  ...props
}: CheckboxProps & { isForced?: boolean }) => {
  return (
    <Checkbox
      {...props}
      width={props.w || props.width || '24px'}
      height='24px'
      isChecked={isForced ? isChecked !== undefined : isChecked}
      colorScheme={isForced && isChecked === false ? 'red' : 'blue'}
      onChange={(e: any) =>
        props.onChange?.(
          !isForced
            ? e?.target?.checked
            : isChecked === true
            ? false
            : isChecked === false
            ? undefined
            : true,
        )
      }
    >
      {children}
    </Checkbox>
  );
};

export default RCheckbox;
