import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { groupBy, orderBy } from 'lodash';
import {
  Box,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
  Text,
} from '@chakra-ui/react';
import DynamicTable from '../table/DynamicTable';
import { allIcons, CirlceIcon, icons } from '../utils/Icon';
import HistoryTable from './HistoryTable';
import { axiosInstance } from '../../api/init';
import FileViewer from '../files/FileViewer';
import RdvTable from './RdvTable';
import { TState, useActions } from '../../redux/store';
import { useCanI, useSubTabs } from '../../redux/actions';
import CommentsTab from './CommentsTab';

type TSubTabs = {
  forms?: JSX.Element[];
};

export const useHandleUpload = () => {
  const toast = useToast();
  const rowData = useSelector((state: TState) => state.rowData);

  return async (files: FileList, id: string) => {
    if (!files) {
      alert('Please select a file first!');
      return;
    }

    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }

    formData.append('id', id);

    try {
      toast({
        title: 'Import en cours',
        status: 'warning',
      });

      const response = await axiosInstance.post(
        `/api/file/${rowData._id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      toast({
        title: `${response.data.message}`,
        status: 'success',
      });
    } catch (error) {
      console.error('Error uploading the file', error);
    }
  };
};

const SubTabs = ({ forms }: TSubTabs) => {
  const appointsHistory = useSelector((state: TState) => state.appointsHistory);
  const rowData = useSelector((state: TState) => state.rowData);
  const currentTab = useSelector((state: TState) => state.currentTab);
  const tabToUpdate = useSelector((state: TState) => state.tabToUpdate);
  const rowHistory = useSelector((state: TState) => state.rowHistory);
  const isEditMode = useSelector((state: TState) => state.isEditMode);
  const modalType = useSelector((state: TState) => state.modalType);
  const theme = useSelector((state: TState) => state.theme);

  const { setModalType, setTabToUpdate } = useActions();
  const subTabs = useSubTabs();
  const canI = useCanI();

  const groupedForm = groupBy(forms, (form) => form.props?.field.parent_id);
  const isAdd = modalType === 'addEntry';

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    setTabIndex(0);
    setTabToUpdate(currentTab);
  }, [currentTab]);

  const correctSubTabs = [...subTabs].slice(1);
  const canHistory = canI('history', 'read');

  return !tabToUpdate ? null : (
    <Box position='relative' height='100%' overflow='hidden'>
      <Tabs
        variant='enclosed'
        isLazy
        onChange={setTabIndex}
        index={tabIndex}
        borderColor='gray.300'
        display='flex'
        flexDirection='column'
        height='100%'
      >
        <TabList flexWrap='wrap'>
          {correctSubTabs?.map((tab, idx) => (
            <Tab key={tab?._id} textTransform='capitalize'>
              {tab?.icon && (
                <Icon as={allIcons[tab?.icon]} boxSize={21} mr={2} />
              )}
              {tab?.title}
              {idx !== 0 && isEditMode && (
                <>
                  <CirlceIcon
                    as={icons.edit}
                    ml={3}
                    onClick={() => {
                      setTabToUpdate(subTabs[idx]);
                      setModalType('editTab');
                    }}
                  />
                  <CirlceIcon
                    as={icons.cross}
                    onClick={() => {
                      setModalType('delTab');
                    }}
                  />
                </>
              )}
            </Tab>
          ))}
          {!isAdd && (
            <>
              <Tab textTransform='capitalize'>
                <Icon as={allIcons.HiCalendarDays} boxSize={21} mr={2} />
                RDV
              </Tab>
              {canHistory && (
                <Tab textTransform='capitalize'>
                  <Icon as={allIcons.HiClock} boxSize={21} mr={2} />
                  Historique
                </Tab>
              )}
              <Tab textTransform='capitalize'>
                <Icon as={allIcons.HiDocumentText} boxSize={21} mr={2} />
                Documents
              </Tab>
              <Tab textTransform='capitalize'>
                <Icon as={allIcons.HiChatBubbleLeftRight} boxSize={21} mr={2} />
                Commentaires
              </Tab>
            </>
          )}
          {isAdd && (
            <Text
              fontSize={theme.fontSize.small}
              p={2}
              ml={3}
              borderRadius='5px'
              bg={theme.dark}
              color={theme.none}
            >
              Sauvegardez le dossier avant d'importer des documents
            </Text>
          )}
        </TabList>
        <TabPanels id='subTabsPanels' overflow='auto' flexGrow={1}>
          {correctSubTabs?.map((tab) => (
            <TabPanel key={tab?._id} p={0}>
              <Box borderWidth='1px' padding='4' borderColor='gray.300'>
                {/addEntry|editEntry/.test(modalType) ? (
                  groupedForm?.[`${tab?._id}`]
                ) : (
                  <DynamicTable tab={tab} />
                )}
              </Box>
            </TabPanel>
          ))}
          {!isAdd && (
            <TabPanel>
              <RdvTable
                data={orderBy(appointsHistory[rowData._id], 'planningDate')}
              />
            </TabPanel>
          )}
          {!isAdd && canHistory && (
            <TabPanel>
              <HistoryTable data={rowHistory[rowData._id]} />
            </TabPanel>
          )}
          {!isAdd && (
            <TabPanel>
              <FileViewer />
            </TabPanel>
          )}
          {!isAdd && (
            <TabPanel h='100%' overflow='hidden' p={0}>
              <CommentsTab />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default SubTabs;
