import { useSelector } from 'react-redux';
import { Box, Button, Flex, Heading, useToast } from '@chakra-ui/react';
import useForm from '../form/Form';
import { login, updateUser } from '../../api/tabsApi';
import { TState, useActions } from '../../redux/store';

export const loginFields = [
  { label: 'Identifiant', name: 'login', type: 'text' },
  { label: 'Mot de passe', name: 'pass', type: 'password' },
];

export const updateLoginFields = [
  { label: 'Nouveau mot de passe', name: 'pass', type: 'password' },
];

export const replaceLoginFields = [
  { label: 'Mot de passe actuel', name: 'prevPass', type: 'password' },
  { label: 'Nouveau mot de passe', name: 'pass', type: 'password' },
];

export type TUser = {
  login: string;
  pass: string;
  userColor?: string;
  name?: string;
  _id?: string;
};

type TLogin = {
  handleLogin: (resp: any) => void;
};

const Login = ({ handleLogin }: TLogin) => {
  const toast = useToast();
  const user = useSelector((state: TState) => state.user);
  const { setUser, setLogin } = useActions();

  const handleConnect = async (formData: TUser) => {
    const resp = await login({
      ...formData,
      company: localStorage.getItem('company'),
    });

    handleLogin(resp);
  };

  const handleUpdate = async (formData: any) => {
    if (user.tempPasswordForced && (!formData.pass || !formData.prevPass)) {
      toast({
        title: 'Champ manquant',
        status: 'error',
        isClosable: true,
      });

      return;
    }

    const resp = await updateUser(user._id, { ...user, ...formData });
    resp && setLogin('');
  };

  const { Form, handleSubmit } = useForm(
    user.tempPassword
      ? updateLoginFields
      : user.tempPasswordForced
      ? replaceLoginFields
      : loginFields,
    user.tempPassword || user.tempPasswordForced ? handleUpdate : handleConnect,
  );

  const isExternal = !/evisioncrm|localhost/.test(window.location.hostname);

  return (
    <Flex
      direction='column'
      boxSize={['100%', '600px']}
      m='auto'
      borderRadius='15px'
      p={10}
      boxShadow='lg'
      bgColor='white'
    >
      <Box margin='auto'>
        <Heading mb='15px'>
          <Flex alignItems='center' justifyContent='center'>
            <img
              src={
                isExternal
                  ? `logo${localStorage.getItem('company')}big.png`
                  : '/logoEVision.png'
              }
              style={{ height: '50px', marginRight: '7px' }}
            />
            {!isExternal && 'EVisionCRM'}
          </Flex>
        </Heading>
        {Form}
        <Button
          className='submit'
          mt={4}
          onClick={handleSubmit}
          bgColor='#000612'
          color='white'
          w='100%'
          _hover={{ bgColor: '#000612', opacity: '.8' }}
        >
          {user.tempPassword || user.tempPasswordForced
            ? 'Mettre à jour'
            : 'Connexion'}
        </Button>
        {user.tempPasswordForced && (
          <Button
            mt={4}
            onClick={() =>
              setUser((u: any) => ({ ...u, tempPasswordForced: false }))
            }
            bgColor='#000612'
            color='white'
            w='100%'
            _hover={{ bgColor: '#000612', opacity: '.8' }}
          >
            Annuler
          </Button>
        )}
      </Box>
    </Flex>
  );
};

export default Login;
