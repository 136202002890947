import { Textarea } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';

type TTextInput = {
  name: string;
  value: string;
  isDisabled: boolean;
  handleOnChange: (name: string, value: string) => void;
};

const TextInput = ({ name, value, isDisabled, handleOnChange }: TTextInput) => {
  const ref = useRef<any>();

  useEffect(() => {
    ref.current.style.height = 'auto';
    ref.current.style.height = `${ref.current.scrollHeight + 2}px`;
  }, [value]);

  return (
    <Textarea
      ref={ref}
      isDisabled={isDisabled}
      p={2}
      minH='35px'
      onChange={(e) => {
        handleOnChange(name, e.target.value);
      }}
      value={value ?? ''}
    />
  );
};

export default TextInput;
