import { useSelector } from 'react-redux';
import { updateCustomSettings } from '../../../api/tabsApi';
import useForm, { TField } from '../../form/Form';
import { TState, useActions } from '../../../redux/store';

const useManageCustom = () => {
  const theme = useSelector((state: TState) => state.theme);

  const { onClose, setTheme } = useActions();

  const handleEdit = async (formData: any) => {
    const formatedData = { [formData.name]: formData.value };
    const resp = await updateCustomSettings(formatedData);

    if (resp.success) {
      setTheme({ ...theme, ...resp.settings });
    }

    onClose();
  };

  const fields: TField[] = [
    { label: 'Nom', name: 'name', type: 'text', disabled: true },
    { label: 'Valeur', name: 'value', type: 'text' },
  ];

  const { Form, handleSubmit } = useForm(fields, handleEdit);

  return {
    editCustom: {
      component: Form,
      action: handleSubmit,
      title: 'Editer paramètre',
      actionLabel: 'Sauvegarder',
    },
  };
};

export default useManageCustom;
