import { useSelector } from 'react-redux';
import { Circle, SquareProps } from '@chakra-ui/react';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
} from '@chakra-ui/icons';
import { entries } from 'lodash';
import { ReactNode } from 'react';
import { IconType } from 'react-icons';
import { BiColumns } from 'react-icons/bi';
import * as hiIcons from 'react-icons/hi2';
import { IoMdLogOut } from 'react-icons/io';
import { TState } from '../../redux/store';

export const CirlceIcon = ({
  icon,
  color,
  boxSize,
  ...props
}: SquareProps & { icon?: ReactNode; boxSize?: string }) => {
  const theme = useSelector((state: TState) => state.theme);

  return (
    <Circle
      boxSize={boxSize || theme.fontSize.headerBig}
      padding='5px'
      color={color || theme.normal}
      cursor={props.onClick ? 'pointer' : 'default'}
      mx={0.5}
      _hover={{
        ...props._hover,
      }}
      {...props}
      bgColor={props.backgroundColor || props.bgColor || theme.btnBg}
    >
      {icon}
    </Circle>
  );
};

export const NormalIcon = ({
  icon,
  color,
  boxSize,
  ...props
}: SquareProps & { icon?: ReactNode; boxSize?: string }) => {
  const theme = useSelector((state: TState) => state.theme);

  return (
    <Circle
      boxSize={boxSize || theme.fontSize.headerBig}
      color={color || theme.normal}
      cursor={props.onClick ? 'pointer' : 'default'}
      mx={0.5}
      _hover={{
        ...props._hover,
      }}
      {...props}
    >
      {icon}
    </Circle>
  );
};

export const icons = {
  logout: IoMdLogOut,
  edit: EditIcon,
  cross: CloseIcon,
  arrowUp: <ChevronUpIcon />,
  arrowDown: <ChevronDownIcon />,
  trash: <DeleteIcon />,
  page: BiColumns,
  column: hiIcons.HiViewColumns,
  duplicate: <hiIcons.HiOutlineDocumentDuplicate />,
};

export const allIcons: Record<string, IconType> = hiIcons;

export const pageIcons: Record<string, IconType> = entries(hiIcons).reduce(
  (prev, [key, value]) =>
    /Outline/.test(key) ? prev : { ...prev, [key]: value },
  {},
);
