import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { io } from 'socket.io-client';
import { useToast } from '@chakra-ui/toast';
import { TState, useActions } from '../redux/store';

const instance = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.request.use(
  (config) => {
    const localToken = localStorage.getItem('token');
    config.headers.Authorization = `Bearer ${localToken}`;
    config.headers.company = localStorage.getItem('company');
    return config;
  },
  (error) => Promise.reject(error),
);

const duration = 3000;

const useInstanceError = () => {
  const [errorToast, setErrorToast] = useState();
  const [successToast, setSuccessToast] = useState();
  const [warningToast, setWarningToast] = useState();
  const toast = useToast();

  useEffect(() => {
    if (errorToast) {
      toast({
        title: errorToast,
        status: 'error',
        duration,
        isClosable: true,
      });

      setErrorToast(undefined);
    }
  }, [errorToast]);

  useEffect(() => {
    if (successToast) {
      toast({
        title: successToast,
        status: 'success',
        duration,
        isClosable: true,
      });

      setSuccessToast(undefined);
    }
  }, [successToast]);

  useEffect(() => {
    if (warningToast) {
      toast({
        title: warningToast,
        status: 'warning',
        duration,
        isClosable: true,
      });

      setWarningToast(undefined);
    }
  }, [warningToast]);

  instance.interceptors.response.use(
    (response) => {
      if (response.data.isWarning || response.data.isSuccess) {
        if (response.data.isWarning) {
          setWarningToast(response.data.message);
        } else {
          setSuccessToast(response.data.message);
        }
      }

      return response;
    },
    (error) => {
      setErrorToast(error?.response.data.message);
      return Promise.reject(error);
    },
  );
};

const useSocketInstance = () => {
  const user = useSelector((state: TState) => state.user);
  const { getNotifs } = useActions();

  const [state, setState] = useState<any>(null);

  useEffect(() => {
    if (!user?._id) return;

    const socketInstance = io(process.env.REACT_APP_BASE_URL, {
      transports: ['websocket'],
    });

    socketInstance.on('ping', (socketId) => {
      socketInstance.emit('initUser', { userId: `${user._id}`, socketId });
    });

    socketInstance?.on('notif', () => {
      getNotifs();
    });

    setState(socketInstance);
  }, [user?._id]);

  return state;
};

export { instance as axiosInstance, useInstanceError, useSocketInstance };
