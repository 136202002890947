import { useSelector } from 'react-redux';
import { TState } from '../../redux/store';
import { allIcons, CirlceIcon } from '../utils/Icon';
import { fetchRowsData } from '../../api/tabsApi';
import { exportToExcel } from '../../helpers';

const DownloadComponent = () => {
  const rowSelected = useSelector((state: TState) => state.rowSelected);
  const currentTab = useSelector((state: TState) => state.currentTab);
  const user = useSelector((state: TState) => state.user);

  const downloadData = async () => {
    const promises: any[] = [];

    if (!rowSelected.length) return;

    const rs = [...rowSelected];

    while (rs.length) {
      const rows = rs.splice(0, 2000);
      currentTab?._id && promises.push(fetchRowsData(currentTab?._id, rows));
    }

    const resps = await Promise.all(promises);
    exportToExcel(resps.flat(), `export_${currentTab?.title}`);
  };

  return user.isAdmin ? (
    <CirlceIcon
      as={allIcons.HiFolderArrowDown}
      onClick={() => downloadData()}
      mr={2}
    />
  ) : null;
};

export default DownloadComponent;
