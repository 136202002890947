import { useEffect } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useInstanceError, useSocketInstance } from './api/init';
import Login from './components/login/Login';
import Navbar from './components/layout/Navbar';
import Main from './components/layout/Main';
import { useSelector } from 'react-redux';
import { TState, useActions } from './redux/store';

type TResponse = { success: boolean; token: string; data: any };

const findElement = (el: Element, selector: string) => {
  if (el.parentElement?.querySelector(selector)) {
    (el.parentElement?.querySelector(selector) as HTMLButtonElement)?.click();
  } else if (el.parentElement) {
    findElement(el.parentElement, selector);
  }
};

const App = () => {
  useInstanceError();
  useSocketInstance();

  const user = useSelector((state: TState) => state.user);
  const token = useSelector((state: TState) => state.token);
  const theme = useSelector((state: TState) => state.theme);

  const { setToken, setLogin } = useActions();

  const handleLogin = (resp: TResponse) => {
    if (!resp?.success) return;
    setToken(resp.token);
    setLogin('');
  };

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Enter' && document.activeElement) {
        findElement(document.activeElement, '.submit');
      }
    });
  }, []);

  return (
    <>
      {!token || user.tempPasswordForced || user.tempPassword ? (
        <Flex
          backgroundImage='/bg.jpg'
          backgroundSize='cover'
          bgRepeat='no-repeat'
          alignItems='center'
          bgPosition='center'
        >
          <Login handleLogin={handleLogin} />
        </Flex>
      ) : (
        <Flex bgColor={theme.lighter} w='100vw' overflow='hidden' maxH='100%'>
          <Box
            position='fixed'
            bgColor={theme.light}
            height='400px'
            width='100%'
            top={0}
            left={0}
          />
          <Navbar />
          <Main />
        </Flex>
      )}
      {/* <Button
        onClick={() => {
          socketInstance?.emit('click', {
            token: user._id,
            selector: '.ifr-call-button',
          });
        }}
      >
        Décrocher
      </Button>
      <Button
        onClick={() => {
          socketInstance?.emit('click', {
            token: user._id,
            selector: '.cc-button-control_hangup',
          });
        }}
      >
        Racrocher
      </Button> */}
      {/* <iframe
        width='600'
        height='600'
        loading='lazy'
        allow='microphone; speaker-selection; autoplay; camera; display-capture; hid'
        sandbox='allow-popups allow-scripts allow-same-origin'
        src={`${
          process.env.REACT_APP_BASE_URL
        }/api/proxy?bearer=${encodeURIComponent(
          token,
        )}&company=${localStorage.getItem('company')}`}
        // src='/api/proxy'
      ></iframe> */}
    </>
  );
};

export default App;
