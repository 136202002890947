import { axiosInstance } from './init';

export const fetchTabs = async () =>
  axiosInstance
    .get('/api/tabs')
    .then((response) => {
      if (response.data.success) {
        return response.data;
      }
    })
    .catch((error) => console.error('Failed to fetch tabs', error));

export const addTab = async (payload: {
  title: any;
  icon?: string;
  parent_id?: string;
  isMenu?: boolean;
  planable?: boolean;
  isPlanning?: boolean;
}) =>
  axiosInstance
    .post('/api/tabs', payload)
    .then((response) => response.data.success)
    .catch((error) => console.error('Failed to create tab', error));

export const updateTab = async (payload: {
  id: any;
  title: any;
  icon?: string;
  planable?: boolean;
}) =>
  axiosInstance
    .put('/api/tabs', payload)
    .then((response) => response.data.success)
    .catch((error) => console.error('Failed to create tab', error));

export const updateTabOrder = async (ids: string[]) =>
  axiosInstance
    .put('/api/tabs/order', { ids })
    .then((response) => response.data.success)
    .catch((error) => console.error('Failed to create tab', error));

export const updateTabFilters = async (id: any, data: any) =>
  axiosInstance
    .put('/api/filters', { id, ...data })
    .then((response) => response.data.success)
    .catch((error) => console.error('Failed to create tab', error));

export const deleteTabFilters = async (tabId: any, id: any) =>
  axiosInstance
    .delete(`/api/filters/${tabId}/${id}`)
    .then((response) => response.data.success)
    .catch((error) => console.error('Failed to create tab', error));

export const duplicateTab = async (payload: any) =>
  axiosInstance
    .post(`/api/tabs/duplicate`, payload)
    .then((response) => response.data.success)
    .catch((error) => console.error('Failed to create tab', error));

export const deleteTab = async (id: any) =>
  axiosInstance
    .delete(`/api/tabs/${id}`)
    .then((response) => response.data.success)
    .catch((error) => console.error('Failed to create tab', error));

export const updateColumn = async (payload: any) =>
  axiosInstance
    .put('/api/column', payload)
    .then((response) => response.data)
    .catch((error) => console.error('Failed to create tab', error));

export const fetchData = async (id: string, isPrefetch = false) =>
  axiosInstance
    .get(`/api/data/${id}${isPrefetch ? '/prefetch' : ''}`)
    .then((response) => response.data)
    .catch((error) => console.error('Failed to create tab', error));

export const fetchComments = async (rowId: string) =>
  axiosInstance
    .get(`/api/comments/${rowId}`)
    .then((response) => response.data)
    .catch((error) => console.error('Failed to create tab', error));

export const addComment = async (payload: any) =>
  axiosInstance
    .post(`/api/comments`, payload)
    .then((response) => response.data)
    .catch((error) => console.error('Failed to create tab', error));

export const fetchDataDetail = async (id: string) =>
  axiosInstance
    .get(`/api/data/${id}/detail`)
    .then((response) => response.data)
    .catch((error) => console.error('Failed to create tab', error));

export const fetchRowData = async (id: string, rowId?: string) =>
  axiosInstance
    .get(`/api/data/${id}/row/${rowId}`)
    .then((response) => response.data)
    .catch((error) => console.error('Failed to create tab', error));

export const fetchRowsData = async (id: string, rowsId?: string[]) =>
  axiosInstance
    .post(`/api/data/download/rows`, { id, rowsId })
    .then((response) => response.data)
    .catch((error) => console.error('Failed to create tab', error));

export const fetchDataCount = async () =>
  axiosInstance
    .get(`/api/data/count/rows`)
    .then((response) => response.data)
    .catch((error) => console.error('Failed to create tab', error));

export const addData = async (payload: any) =>
  axiosInstance
    .post('/api/data', payload)
    .then((response) => response.data)
    .catch((error) => console.error('Failed to create tab', error));

export const updateData = async (payload: any) =>
  axiosInstance
    .put('/api/data', payload)
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const patchData = async (payload: any) =>
  axiosInstance
    .patch('/api/data', payload)
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const deleteDatas = async (ids: string[]) =>
  axiosInstance
    .delete('/api/data', { data: { ids } })
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const deleteData = async (id: any) =>
  axiosInstance
    .delete(`/api/data/${id}`)
    .then((response) => response.data)
    .catch((error) => console.error('Failed to create tab', error));

export const autocomplete = async (payload: any, token?: string) =>
  axiosInstance
    .get(`/api/google/autocomplete/${payload}/${token}`)
    .then((response) => response.data)
    .catch((error) => console.error('Failed to create tab', error));

export const getAddress = async (payload: any) =>
  axiosInstance
    .get(`/api/google/address/${payload}`)
    .then((response) => response.data)
    .catch((error) => console.error('Failed to create tab', error));

export const login = async (payload: any) =>
  axiosInstance
    .post('/api/users/login', payload)
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const getUsers = async () =>
  axiosInstance
    .get('/api/users')
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const getRoles = async () =>
  axiosInstance
    .get('/api/roles')
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const getUserRole = async () =>
  axiosInstance
    .get('/api/roles/user')
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const addRoles = async (payload: any) =>
  axiosInstance
    .post('/api/roles', payload)
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const updateRoles = async (payload: any) =>
  axiosInstance
    .put('/api/roles', payload)
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const patchRoles = async (payload: { ids: string[]; roles: any }) =>
  axiosInstance
    .patch('/api/roles', payload)
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const deleteRole = async (id: any) =>
  axiosInstance
    .delete(`/api/roles/${id}`)
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const deleteRoles = async (ids: string[]) =>
  axiosInstance
    .delete(`/api/roles`, { data: { ids } })
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const addUser = async (payload: any) =>
  axiosInstance
    .post('/api/users/add', payload)
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const deleteUser = async (id: string) =>
  axiosInstance
    .delete(`/api/users/${id}`)
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const deleteUsers = async (ids: string[]) =>
  axiosInstance
    .delete(`/api/users/`, { data: { ids } })
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const updateUser = async (userId: any, payload: any) =>
  axiosInstance
    .put(`/api/users/${userId}`, payload)
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const updateUsers = async (payload: any) =>
  axiosInstance
    .put(`/api/users/`, payload)
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const fetchInputs = async () =>
  axiosInstance
    .get('/api/inputs')
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const addInput = async (payload: any) =>
  axiosInstance
    .post('/api/inputs/', payload)
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const updateInput = async (payload: any) =>
  axiosInstance
    .put(`/api/inputs/${payload._id}`, payload)
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const updateInputs = async (payload: any) =>
  axiosInstance
    .put(`/api/inputs/`, payload)
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const deleteInput = async (id: any) =>
  axiosInstance
    .delete(`/api/inputs/${id}`)
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const deleteInputs = async (ids: string[]) =>
  axiosInstance
    .delete(`/api/inputs/`, { data: { ids } })
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const addUploadMatch = async (
  matchs: { inputKey: string; fileKey: string }[],
) =>
  axiosInstance
    .post(`/api/inputs/match`, { matchs })
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const fetchMatch = async () =>
  axiosInstance
    .get(`/api/inputs/match`)
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const fetchFiles = async (rowId: string) =>
  axiosInstance
    .get(`/api/file/${rowId}`)
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const fetchFile = async (fileId: string) =>
  axiosInstance
    .get(`/api/file/id/${fileId}`, {
      responseType: 'blob',
    })
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const downloadDocuments = async (documentsId: string, rowId: string) =>
  axiosInstance
    .post(
      `/api/file/download/archive`,
      { documentsId, rowId },
      { responseType: 'blob' },
    )
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const deleteFile = async (id: string) =>
  axiosInstance
    .delete(`/api/file/${id}`)
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const fetchDocumentList = async () =>
  axiosInstance
    .get(`/api/document`)
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const fetchNotifs = async () =>
  axiosInstance
    .get(`/api/notif`)
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const getNotifFile = async (id: string) =>
  axiosInstance
    .get(`/api/notif/file/${id}`, {
      responseType: 'blob',
    })
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const updateNotif = async (id: string, payload: any) =>
  axiosInstance
    .patch(`/api/notif/one/${id}`, payload)
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const updateNotifs = async (payload: any) =>
  axiosInstance
    .patch(`/api/notif/all`, payload)
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const deleteNotif = async (id: string) =>
  axiosInstance
    .delete(`/api/notif/one/${id}`)
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const deleteNotifs = async () =>
  axiosInstance
    .delete(`/api/notif/all`)
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const postDocumentList = async (payload: any) =>
  axiosInstance
    .post(`/api/document`, payload)
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const updateDocumentList = async (id: string, payload: any) =>
  axiosInstance
    .put(`/api/document/${id}`, payload)
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const updateCustomSettings = async (payload: any) =>
  axiosInstance
    .put(`/api/settings/custom`, payload)
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const deleteDocumentList = async (id: string) =>
  axiosInstance
    .delete(`/api/document/${id}`)
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to create tab', error));

export const generateIcon = async (text: string) =>
  axiosInstance
    .get(`/api/openai/icon`, { params: { text } })
    .then((response) => response?.data)
    .catch((error) => console.error('Failed to generate icon', error));
