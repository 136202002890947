import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Box, Flex, Text } from '@chakra-ui/react';
import { TState } from '../../redux/store';
import { addComment, fetchComments } from '../../api/tabsApi';
import MentionInput from '../form/MentionInput';

const Comment = ({
  isCurrentUser,
  content,
  userAndDate,
}: {
  isCurrentUser?: boolean;
  content: string;
  userAndDate: string;
}) => {
  const theme = useSelector((state: TState) => state.theme);

  return (
    <Flex justifyContent={isCurrentUser ? 'right' : 'left'}>
      <Box
        className='comment-message'
        backgroundColor={isCurrentUser ? theme.lighter : theme.light}
        color={theme.darker}
        boxShadow='xl'
        maxW='60%'
        minW='40%'
        w='fit-content'
        px={3}
        py={2}
        borderRadius={5}
        borderBottomLeftRadius={isCurrentUser ? 5 : 0}
        borderBottomRightRadius={isCurrentUser ? 0 : 5}
        mb={10}
        fontSize={theme.fontSize.normal}
      >
        <Box dangerouslySetInnerHTML={{ __html: content }} />
        <Box
          float='right'
          pt={3}
          fontStyle='italic'
          fontSize={theme.fontSize.small}
        >
          {userAndDate}
        </Box>
      </Box>
    </Flex>
  );
};

const CommentsTab = () => {
  const ref = useRef<any>();
  const rowData = useSelector((state: TState) => state.rowData);
  const currentTab = useSelector((state: TState) => state.currentTab);
  const theme = useSelector((state: TState) => state.theme);

  const [comments, setComments] = useState<any[]>([]);

  const getComments = useCallback(async () => {
    const resp = await fetchComments(rowData._id);
    setComments(resp.data);
  }, [rowData._id]);

  const addComm = async (data: { comment: string; usersId: string[] }) => {
    if (!data.comment) return;

    await addComment({
      ...data,
      rowId: rowData._id,
      tabId: currentTab?._id,
    });

    await getComments();
  };

  useEffect(() => {
    getComments();
  }, []);

  useEffect(() => {
    ref.current.scrollTop = ref.current.scrollHeight;
  }, [comments]);

  return (
    <Flex direction='column' h='100%' overflow='hidden'>
      <Flex
        ref={ref}
        flexGrow={1}
        direction='column'
        overflow='auto'
        mt={theme.margin}
      >
        {comments.map((comment) => (
          <Comment
            content={comment.comment}
            userAndDate={`${comment.user} - ${moment(comment.date).format(
              'DD/MM/YYYY HH:mm',
            )}`}
            isCurrentUser={comment.isCurrentUser}
          />
        ))}
      </Flex>
      <MentionInput callback={addComm} />
      <Text fontStyle='italic' fontSize={theme.fontSize.xSmall} mt={1}>
        Utilise @ pour notifier un utilisateur
      </Text>
    </Flex>
  );
};

export default CommentsTab;
