import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { groupBy } from 'lodash';
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import {
  deleteFile,
  fetchFile,
  fetchFiles,
  updateData,
} from '../../api/tabsApi';
import { useHandleUpload } from '../tabs/SubTabs';
import { allIcons, CirlceIcon, icons } from '../utils/Icon';
import { colors } from '../../theme';
import DocumentTable from '../tabs/DocumentTable';
import { TState, useActions } from '../../redux/store';
import Loader from '../common/Loader';
import { downloadFile } from '../../helpers';
import { useCanI } from '../../redux/actions';

const ViewByType = ({
  file,
  fileUrl,
  filesLength,
  setCurr,
}: {
  file: File;
  fileUrl: string;
  filesLength: number;
  setCurr: (fn: (nb: number) => number) => void;
}) => {
  let component;

  switch (true) {
    case /pdf/i.test(file?.type):
      component = (
        <iframe src={fileUrl} width='100%' height='100%' title='PDF Viewer' />
      );
      break;
    case /image/i.test(file?.type):
      component = (
        <Box boxSize='inherit'>
          <img
            className='imgViewer'
            src={fileUrl}
            alt='Image preview'
            max-width='100%'
            max-height='100%'
          />
        </Box>
      );
      break;
    case /audio/i.test(file?.type):
      component = <audio controls src={fileUrl} />;
      break;
    case /video/i.test(file?.type):
      component = (
        <video
          controls
          width='auto'
          height='auto'
          style={{ maxHeight: '100%' }}
        >
          <source src={fileUrl} type='video/mp4' />
          Votre navigateur ne supporte pas le tag vidéo.
        </video>
      );
      break;
    case undefined:
      component = <p>Pas de fichier</p>;
      break;
    default:
      component = <p>Type de fichier non supporté.</p>;
  }

  return (
    <Flex
      alignItems='center'
      flexGrow={1}
      overflow='hidden'
      userSelect='none'
      position='relative'
    >
      {filesLength > 1 ? (
        <CirlceIcon
          as={allIcons.HiChevronLeft}
          onClick={() => setCurr((c) => (c - 1 < 0 ? filesLength - 1 : c - 1))}
        />
      ) : null}
      <Flex
        mx={3}
        flexGrow={1}
        height='75vh'
        overflow='auto'
        alignItems='center'
        justifyContent='center'
      >
        {component}
      </Flex>
      {filesLength > 1 ? (
        <CirlceIcon
          as={allIcons.HiChevronRight}
          onClick={() => setCurr((c) => (c + 1 > filesLength - 1 ? 0 : c + 1))}
        />
      ) : null}
    </Flex>
  );
};

const FileViewer = () => {
  const rowData = useSelector((state: TState) => state.rowData);
  const documentList = useSelector((state: TState) => state.documentList);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getDataDetail } = useActions();
  const canI = useCanI();

  const handleUpload = useHandleUpload();

  const [files, setFiles] = useState<
    (File & { _id: string; filename?: string })[]
  >([]);
  const [modalFilesId, setModalFilesId] = useState<string>('');
  const [curr, setCurr] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [fileUrls, setFileUrls] = useState<Record<string, string>>({});

  const groupFiles = groupBy(files, 'documentId');
  const modalFiles = groupFiles[modalFilesId] || [];

  const file = modalFiles[curr] || {};
  const fileUrl = fileUrls[file._id];
  const canDelete = canI(modalFilesId, 'delete');
  const canDownload = canI(modalFilesId, 'download');

  const getFiles = useCallback(async () => {
    if (rowData._id) {
      setFiles([]);
      setIsLoading(true);

      const resp = await fetchFiles(rowData._id);

      setFiles(resp.data);
      setIsLoading(false);
    }
  }, [rowData._id]);

  useEffect(() => {
    rowData?._id && getFiles();
  }, [rowData._id]);

  useEffect(() => {
    if (!file?._id || fileUrl) return;

    // Récupérer l'URL du fichier depuis le serveur backend
    const getFile = async () => {
      try {
        const respData = await fetchFile(file._id);

        // Créer une URL blob pour l'objet reçu
        const url = URL.createObjectURL(respData);
        setFileUrls((f) => ({ ...f, [file._id]: url }));
      } catch (error) {
        console.error('Erreur lors de la récupération du fichier:', error);
      }
    };

    getFile();
  }, [file?._id]);

  useEffect(() => {
    setCurr((c) => (c - 1 < 0 ? modalFiles.length - 1 : c - 1));
  }, [modalFiles.length]);

  const handleDownload = useCallback(async () => {
    await downloadFile(fileUrl, file.filename, true);
  }, [fileUrl, file]);

  return (
    <>
      {isLoading && <Loader />}
      <DocumentTable
        data={documentList}
        onView={(dataId: any) => {
          setCurr(0);
          setModalFilesId(dataId);
          onOpen();
        }}
        onUpload={async (dataId: string, file: any) => {
          await handleUpload(file, dataId);
          getDataDetail({ rowId: rowData._id });
          getFiles();
        }}
        onUpdate={async (payload: any) => {
          await updateData(payload);
          getDataDetail({ rowId: rowData._id });
        }}
        groupFiles={groupFiles}
      />
      <Modal isOpen={isOpen} onClose={onClose} size='full'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {documentList?.find((dl) => dl._id === modalFilesId)?.name}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody display='flex'>
            <ViewByType
              setCurr={setCurr}
              file={file}
              fileUrl={fileUrl}
              filesLength={modalFiles.length}
            />
          </ModalBody>
          <ModalFooter>
            {isDeleteConfirm ? (
              <>
                <Button
                  bgColor={`${colors.main}.400`}
                  onClick={async () => {
                    await deleteFile(file._id);
                    getDataDetail({ rowId: rowData._id });
                    getFiles();
                    setIsDeleteConfirm(false);
                  }}
                  mr={3}
                >
                  Confirmer
                </Button>
                <Button onClick={() => setIsDeleteConfirm(false)}>
                  Annuler
                </Button>
              </>
            ) : modalFiles.length ? (
              <Flex gap={1}>
                {canDownload && (
                  <CirlceIcon
                    as={allIcons.HiMiniArrowDownOnSquareStack}
                    onClick={handleDownload}
                  />
                )}
                {canDelete && (
                  <CirlceIcon
                    backgroundColor='red.500'
                    color='white'
                    cursor='pointer'
                    _hover={{
                      backgroundColor: 'white',
                      color: 'red.500',
                      fontSize: '1.5rem',
                    }}
                    icon={icons.trash}
                    onClick={() => setIsDeleteConfirm(true)}
                  />
                )}
              </Flex>
            ) : null}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FileViewer;
