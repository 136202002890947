import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Text, Input, Flex } from '@chakra-ui/react';
import { entries, groupBy } from 'lodash';
import { addRoles, deleteRoles, updateRoles } from '../../../api/tabsApi';
import TreeInput from '../../form/TreeInput';
import { allIcons } from '../../utils/Icon';
import { addressInputs, addressInputsRoles } from './ManageInputs';
import { TState, useActions } from '../../../redux/store';
import { useCanI } from '../../../redux/actions';

export const rolesActions = [
  { title: 'create', label: 'créer' },
  { title: 'read', label: 'voir' },
  { title: 'update', label: 'modifier' },
  // { title: 'delete', label: 'supprimer' },
];

export const planningRolesActions = [
  { title: 'create', label: 'créer' },
  { title: 'read', label: 'voir' },
  { title: 'update', label: 'modifier' },
  // { title: 'delete', label: 'supprimer' },
  { title: 'confirm', label: 'confirmer' },
];

export const rolesActionsWithoutDelete = [
  { title: 'create', label: 'créer' },
  { title: 'read', label: 'voir' },
  { title: 'update', label: 'modifier' },
];

export const documentsRoles = [
  { title: 'read', label: 'voir' },
  { title: 'add', label: 'ajouter' },
  { title: 'comment', label: 'commenter' },
  { title: 'validate', label: 'valider' },
  { title: 'delete', label: 'supprimer' },
  { title: 'download', label: 'télécharger' },
];

export const RoleTree = ({
  setRoleName,
  handleTreeChange,
  forcedAction,
}: {
  setRoleName?: (e: any) => void;
  handleTreeChange: (e: any) => void;
  forcedAction?: boolean;
}) => {
  const tabs = useSelector((state: TState) => state.tabs);
  const rowData = useSelector((state: TState) => state.rowData);
  const dataInputs = useSelector((state: TState) => state.dataInputs);
  const documentList = useSelector((state: TState) => state.documentList);
  const theme = useSelector((state: TState) => state.theme);
  const isSuper = useSelector((state: TState) => state.isSuper);

  const [filter, setFilter] = useState('');

  const allInputs = dataInputs?.filter(
    (i) => !/planning_type/.test(i.name_id as string),
  );

  const groupedData = groupBy(allInputs, 'group');

  return (
    <>
      <Flex gap={3} alignItems='center' mb={5}>
        {setRoleName && (
          <>
            <Text fontSize={theme.fontSize.small} fontWeight='bold'>
              Nom
            </Text>
            <Input
              type={'text'}
              onChange={(e) => setRoleName(e.target.value)}
              defaultValue={rowData.name}
              placeholder='Nom du role'
            />
          </>
        )}
        <Text fontSize={theme.fontSize.small} fontWeight='bold'>
          Filtre
        </Text>
        <Input
          type={'text'}
          onChange={(e) => setFilter(e.target.value)}
          placeholder='Filtrer'
        />
      </Flex>
      {!isSuper && (
        <>
          <TreeInput
            list={[
              {
                id: 'inputs',
                name: 'Champs',
                childs: entries(groupedData).map(([dataKey, dataInput]) => ({
                  id: `inputs-${dataKey}`,
                  name: dataKey !== 'undefined' ? dataKey : 'Champs de base',
                  childs: dataInput?.flatMap((input) =>
                    input.type === 'address'
                      ? addressInputsRoles.map((key, idx) => ({
                          id: `${input.name_id}_${key}`,
                          name: `${
                            addressInputs[idx] || 'Adresse validation'
                          } ${input.label} (${input.name_id})`,
                        }))
                      : {
                          id: input.name_id,
                          name: `${input.label} (${input.name_id})`,
                        },
                  ),
                })),
              },
            ]}
            actions={rolesActionsWithoutDelete}
            selected={rowData.roles}
            onChange={handleTreeChange}
            filter={filter}
            forcedAction={forcedAction}
          />
          <TreeInput
            list={[
              {
                id: 'tabs',
                name: 'Pages',
                childs: tabs.map((tab) => ({
                  id: tab._id,
                  name: tab.title,
                  icon: tab.icon,
                  noSpread: true,
                  childs: tab.menuChilds?.map((mc) => ({
                    id: mc._id,
                    name: mc.title,
                    icon: mc.icon,
                  })),
                })),
              },
            ]}
            actions={rolesActionsWithoutDelete}
            selected={rowData.roles}
            onChange={handleTreeChange}
            filter={filter}
            forcedAction={forcedAction}
          />
          <TreeInput
            list={[
              {
                name: 'Planning',
                id: 'planning',
                icon: allIcons.HiCalendarDays,
              },
            ]}
            actions={planningRolesActions}
            selected={rowData.roles}
            onChange={handleTreeChange}
            forcedAction={forcedAction}
          />
          <TreeInput
            list={[
              {
                name: 'Doublons',
                id: 'duplicate',
                icon: allIcons.HiDocumentDuplicate,
              },
            ]}
            actions={[{ title: 'read', label: 'voir' }]}
            selected={rowData.roles}
            onChange={handleTreeChange}
            forcedAction={forcedAction}
          />
          <TreeInput
            list={[
              {
                name: 'Historique',
                id: 'history',
                icon: allIcons.HiClock,
              },
            ]}
            actions={[{ title: 'read', label: 'voir' }]}
            selected={rowData.roles}
            onChange={handleTreeChange}
            forcedAction={forcedAction}
          />
        </>
      )}
      <TreeInput
        list={[
          {
            name: 'Documents',
            id: 'documents',
            icon: allIcons.HiDocumentText,
            childs: documentList.map((doc) => ({
              id: doc._id,
              name: doc.name,
            })),
          },
        ]}
        actions={documentsRoles}
        selected={rowData.roles}
        onChange={handleTreeChange}
        forcedAction={forcedAction}
      />
    </>
  );
};

const useManageRoles = () => {
  const rowData = useSelector((state: TState) => state.rowData);
  const modalType = useSelector((state: TState) => state.modalType);
  const { onClose, getRolesList } = useActions();
  const canI = useCanI();

  const [roleName, setRoleName] = useState(rowData.name);
  const [treeValue, setTreeValue] = useState<Record<string, any>>(
    rowData.roles,
  );

  useEffect(() => {
    setRoleName(rowData.name);
    setTreeValue(rowData.roles);
  }, [rowData]);

  const handleTreeChange = useCallback(
    (v: Record<string, boolean>) => {
      setTreeValue((allValues) => ({ ...allValues, ...v }));
    },
    [setTreeValue],
  );

  const handleRole = async () => {
    const submitAction = modalType === 'addRole' ? addRoles : updateRoles;

    await submitAction({
      name: roleName,
      roles: entries(treeValue).reduce(
        (prev, [k, v]) => (!v ? prev : { ...prev, [k]: v }),
        {},
      ),
      _id: rowData?._id,
    });

    getRolesList({ forced: true });

    onClose();
  };

  const handleDeleteRole = async () => {
    await deleteRoles(rowData._id);
    getRolesList({ forced: true });
    onClose();
  };

  const deletePermission = canI('roles', 'delete');

  const renderDeleteRole = <Text>Confirm you want to delete this role ?</Text>;

  const renderManageRoles = (
    <RoleTree handleTreeChange={handleTreeChange} setRoleName={setRoleName} />
  );

  return {
    addRole: {
      component: renderManageRoles,
      action: handleRole,
      title: 'Ajouter rôle',
      actionLabel: 'Ajouter',
    },
    editRole: {
      component: renderManageRoles,
      canDelete: deletePermission ? 'deleteRole' : '',
      action: handleRole,
      title: 'Editer rôle',
      actionLabel: 'Sauvegarder',
    },
    deleteRole: {
      component: renderDeleteRole,
      action: handleDeleteRole,
      title: 'Supprimer rôle',
      actionLabel: 'Confirmer',
    },
  };
};

export default useManageRoles;
