import { useState } from 'react';
import { useSelector } from 'react-redux';
import { entries, keyBy } from 'lodash';
import { Flex, Tag } from '@chakra-ui/react';
import ModalTable from '../modal/ModalTable';
import NormalTable, { TNormalTable } from '../table/NormalTable';
import { TState, useActions } from '../../redux/store';

export const defaultCustomData = [
  {
    name: 'none',
    value: 'default',
  },
  {
    name: 'main',
    value: 'default',
  },
  {
    name: 'mainBis',
    value: 'default',
  },
  {
    name: 'secondary',
    value: 'default',
  },
  {
    name: 'btnBg',
    value: 'default',
  },
  {
    name: 'lighter',
    value: 'default',
  },
  {
    name: 'light',
    value: 'default',
  },
  {
    name: 'normal',
    value: 'default',
  },
  {
    name: 'dark',
    value: 'default',
  },
  {
    name: 'darker',
    value: 'default',
  },
];

const usersColumns = [
  {
    header: <>Login</>,
    accessorKey: 'login',
    meta: { _id: 'login' },
  },
  {
    header: <>Nom</>,
    accessorKey: 'name',
    meta: { _id: 'name' },
  },
  {
    accessorKey: 'role',
    meta: { _id: 'role' },
  },
  {
    header: <>Désactivé</>,
    accessorKey: 'blocked',
    meta: { _id: 'blocked', type: 'switch' },
  },
  {
    header: <>Sans restriction</>,
    accessorKey: 'noRestrict',
    meta: { _id: 'noRestrict', type: 'switch' },
  },
  {
    header: <>MDP Temporaire</>,
    accessorKey: 'tempPassword',
    meta: { _id: 'tempPassword' },
  },
];

const rolesColumns = [
  {
    header: <>Nom</>,
    accessorKey: 'name',
    meta: { _id: 'name' },
  },
];

const inputsColumns = [
  {
    header: <>Nom</>,
    accessorKey: 'label',
    meta: { _id: 'label' },
  },
  {
    accessorKey: 'ID',
    accessorFn: (row: any) => row.name_id,
    meta: { _id: 'name_id' },
  },
  {
    accessorKey: 'group',
    meta: { _id: 'group' },
  },
  {
    accessorKey: 'type',
    meta: { _id: 'type' },
  },
];

const documentColumns = [
  {
    header: <>Nom</>,
    accessorKey: 'name',
    meta: { _id: 'name' },
  },
  {
    header: <>Description</>,
    accessorKey: 'description',
    meta: { _id: 'description' },
  },
];

const customColumns = [
  {
    header: <>Nom</>,
    accessorKey: 'name',
    meta: { _id: 'name' },
  },
  {
    header: <>Valeur</>,
    accessorKey: 'value',
    meta: { _id: 'value', colorpicker: true },
  },
];

const SettingsTabs = () => {
  const dataInputs = useSelector((state: TState) => state.dataInputs);
  const userList = useSelector((state: TState) => state.userList);
  const roleList = useSelector((state: TState) => state.roleList);
  const settingsTabs = useSelector((state: TState) => state.settingsTabs);
  const tabToUpdate = useSelector((state: TState) => state.tabToUpdate);
  const documentList = useSelector((state: TState) => state.documentList);
  const theme = useSelector((state: TState) => state.theme);

  const {
    setTabToUpdate,
    getUsersList,
    getRolesList,
    getInputs,
    getDocumentList,
  } = useActions();

  const [tabIndex, setTabIndex] = useState(0);

  const rolesById = keyBy(roleList, '_id');

  const tables: Record<string, TNormalTable> = {
    inputs: {
      data: dataInputs || [],
      columns: inputsColumns,
      editEntryKey: 'editInput',
      onLoad: getInputs,
      pendingKey: 'global/async/getInputs/pending',
    },
    users: {
      data: [
        ...userList.map((u) => ({ ...u, role: rolesById[u.role_id]?.name })),
      ].reverse(),
      columns: usersColumns,
      editEntryKey: 'editUser',
      onLoad: async () => {
        getRolesList();
        getUsersList();
      },
      pendingKey: 'global/async/getUsersList/pending',
    },
    roles: {
      data: roleList || [],
      columns: rolesColumns,
      editEntryKey: 'editRole',
      onLoad: getRolesList,
      pendingKey: 'global/async/getRolesList/pending',
    },
    documents: {
      data: documentList || [],
      columns: documentColumns,
      editEntryKey: 'editDocument',
      onLoad: getDocumentList,
      pendingKey: 'global/async/getDocumentList/pending',
    },
    custom: {
      data: entries(theme)
        .map(([k, v]) => ({ name: k, value: v }))
        .filter((row) => !/fontsize|margin/gi.test(row.name)),
      columns: customColumns,
      editEntryKey: 'editCustom',
      pendingKey: 'global/async/getUsersList/pending',
    },
  };

  return (
    <Flex flexDirection='column' gap={3} overflow='auto'>
      <Flex gap={2}>
        {settingsTabs.map((setting, idx) => (
          <Tag
            key={setting._id}
            bgColor={tabIndex === idx ? theme.normal : theme.none}
            color={tabIndex === idx ? theme.mainBis : theme.main}
            fontWeight='500'
            textTransform='capitalize'
            p={2}
            px={4}
            onClick={() => {
              setTabIndex(idx);
              setTabToUpdate(setting);
            }}
            cursor='pointer'
          >
            {setting.title}
          </Tag>
        ))}
      </Flex>
      {tables[tabToUpdate?._id || ''] && (
        <NormalTable {...tables[tabToUpdate?._id as any]} />
      )}
      <ModalTable />
    </Flex>
  );
};

export default SettingsTabs;
