import { useSelector } from 'react-redux';
import { Box } from '@chakra-ui/react';
import { TState } from '../../redux/store';

const Chip = ({
  isBig,
  nb,
  bgColor,
  color,
}: {
  isBig?: boolean;
  nb: string | number;
  bgColor?: string;
  color?: string;
}) => {
  const theme = useSelector((state: TState) => state.theme);
  return (
    <Box
      px={1}
      py={0.3}
      bgColor={bgColor || theme.lighter}
      color={color || theme.darker}
      borderRadius='100px'
      fontSize={isBig ? theme.fontSize.xSmall : '8.5px'}
      fontWeight={600}
      style={
        isBig
          ? {}
          : {
              position: 'absolute',
              top: 3,
              right: 3,
            }
      }
    >
      {`${nb || 0}`}
    </Box>
  );
};

export default Chip;
