import { forwardRef } from 'react';
import Select from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

const cutomStyle = {
  container: (base: any) => ({
    ...base,
    fontSize: '12px',
  }),
};

const RSelect = forwardRef(
  (
    props: StateManagerProps<any, any, any> & React.RefAttributes<any>,
    ref: any,
  ) => {
    return (
      <Select
        ref={ref}
        className='rSelect'
        menuPosition='fixed'
        {...props}
        styles={{ ...cutomStyle, ...props.styles }}
      />
    );
  },
);

export default RSelect;
