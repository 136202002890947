import { useRef, useState } from 'react';
import { InputActionMeta } from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { Box, Flex } from '@chakra-ui/react';
import { getAddress, autocomplete } from '../../api/tabsApi';
import { addressFormat } from '../../helpers';
import RSelect from './RSelect';

type TAutoComplete = {
  setFormData: (data: any) => void;
  addressValue: {
    street: any;
    city: any;
    postal: any;
  };
  label: string;
  noPlaceId?: boolean;
  setPending?: (val: boolean) => void;
  isDisabled?: boolean;
  type?: 'street' | 'city' | 'postal';
};

let timeoutAutocomplete: any = null;

const AddressAutocomplete = ({
  setFormData,
  addressValue,
  label,
  setPending,
  isDisabled,
  type,
}: TAutoComplete) => {
  const [predictions, setPredictions] = useState<any[]>([]);
  const value = type && addressValue[type];
  const isPostal = type === 'postal';

  const autocompleteToken = useRef(uuidv4());
  const autocompleteTokenCount = useRef(0);

  const ref = useRef<HTMLInputElement>();

  const loadOptions = async (val: any) => {
    if (
      autocompleteTokenCount.current > 0 &&
      autocompleteTokenCount.current % 10 === 0
    ) {
      autocompleteToken.current = uuidv4();
    }

    autocompleteTokenCount.current++;

    const response =
      val &&
      (await autocomplete(
        isPostal ? val : `${addressValue.postal || ''} ${val}`,
        autocompleteToken.current,
      ));

    setPredictions(
      response?.predictions?.map((p: any) => ({
        value: p.place_id,
        label: p.description.replace(', France', ''),
      })),
    );
  };

  const handleInputChange = (val: string, event?: InputActionMeta) => {
    if (event?.action && !/input-change/.test(event?.action)) return;

    setFormData((d: any) => {
      const addr = {
        street: d[`${label}_street`],
        city: d[`${label}_city`],
        postal: d[`${label}_postal`],
      };

      return {
        ...d,
        [`${label}_${type}`]: val,
        [`${label}_addressDescription`]: addressFormat(addr),
      };
    });

    clearTimeout(timeoutAutocomplete);
    timeoutAutocomplete = setTimeout(() => {
      loadOptions(val);
    }, 300);
  };

  const handleOnChange = async (prediction: any) => {
    setPending?.(true);

    const resp = await getAddress(prediction.value);

    const { street, locality, administrative_area_level_2, postal_code } =
      resp.data;

    const addr = {
      street,
      city: locality,
      postal: postal_code,
    };

    setFormData((d: any) => ({
      ...d,
      [`${label}_street`]: street,
      [`${label}_city`]: locality,
      [`${label}_dept`]: administrative_area_level_2,
      [`${label}_postal`]: postal_code,
      [`${label}`]: prediction.value,
      [`${label}_addressDescription`]: addressFormat(addr),
    }));

    setPending?.(false);
    setPredictions([]);
  };

  const customStyles = isDisabled
    ? {
        input: (base: any) => ({
          ...base,
          visibility: 'visible',
          color: '#999999',
        }),
      }
    : {};

  return (
    <Flex alignItems='center'>
      <Box flexGrow={1} className='address-autocomplete'>
        <RSelect
          isDisabled={isDisabled}
          ref={ref as any}
          blurInputOnSelect
          options={predictions}
          onInputChange={(val, event) => handleInputChange(val, event)}
          filterOption={() => true}
          onChange={handleOnChange}
          styles={customStyles}
          inputValue={value}
        />
      </Box>
    </Flex>
  );
};

export default AddressAutocomplete;
