import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDisclosure, Flex, Box, Button, Icon } from '@chakra-ui/react';
import { keyBy, uniq } from 'lodash';
import { TState } from '../../redux/store';
import { allIcons } from '../utils/Icon';
import RSelect from './RSelect';

const setCursorPosition = (container: any, position: number) => {
  const range = document.createRange();
  const selection = window.getSelection();

  let charIndex = 0;
  let found = false;

  const traverseNodes = (node: any) => {
    if (found) return;

    if (node.nodeType === Node.TEXT_NODE) {
      const textLength = node.textContent?.length || 0;

      if (charIndex + textLength >= position) {
        // Set range within this text node
        range.setStart(node, position - charIndex);
        found = true;
      } else {
        charIndex += textLength;
      }
    } else {
      // Traverse child nodes for elements like <p>, <i>, etc.
      node.childNodes.forEach(traverseNodes);
    }
  };

  traverseNodes(container);

  if (found) {
    range.collapse(true);
    selection?.removeAllRanges();
    selection?.addRange(range);
  } else {
    console.warn('Position out of bounds');
  }
};

const getCursorPosition = (container: HTMLElement): number => {
  const selection = window.getSelection();
  if (!selection || !selection.rangeCount) return -1; // No selection or cursor

  const range = selection.getRangeAt(0);
  let charIndex = 0;

  const traverseNodes = (node: Node): boolean => {
    if (node === range.startContainer) {
      charIndex += range.startOffset;
      return true;
    }

    if (node.nodeType === Node.TEXT_NODE) {
      charIndex += node.textContent?.length || 0;
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      for (let i = 0; i < node.childNodes.length; i++) {
        if (traverseNodes(node.childNodes[i])) {
          return true;
        }
      }
    }

    return false;
  };

  traverseNodes(container);
  return charIndex;
};

const insertAtPosition = (
  original: string,
  textToInsert: string,
  value: string | null,
  position: number,
  noTag?: boolean,
) => {
  const strings = original
    .replace(/<span[^>]*>(.*?)<\/span>/g, '$1')
    .replace(/<p contentEditable="false" (data="[^>]+")?>/gi, '@tag@open$1@tag')
    .replace(/<\/p>/gi, '@tag@close@tag')
    .replace(/<div>/gi, '@tag@openDiv@tag')
    .replace(/<\/div>/gi, '@tag@closeDiv@tag')
    .replace(/<br>/gi, '@tag@openbr@tag')
    .replace(/&nbsp;/gi, ' ')
    .split('@tag');

  let count = position;
  let isDone = false;

  strings.forEach((s, index) => {
    if (!/@open|@close/.test(s) && !isDone) {
      const textArea = document.createElement('div');
      textArea.innerHTML = s;
      const correctL = textArea.textContent || '';

      if (count - correctL.length <= 0) {
        isDone = true;

        const insert = noTag ? textToInsert : `@open@${textToInsert}@close `;

        strings[index] = `${correctL.slice(
          0,
          noTag ? count : count - 1,
        )}${insert}${correctL.slice(count)}`;
      }

      count -= correctL.length;
    }
  });

  return strings
    .join('')
    .replace(/ /g, '&nbsp;')
    .replace(/<[^>]*>/g, '')
    .replace(/@openDiv/g, '<div>')
    .replace(/@closeDiv/g, '</div>')
    .replace(/@openbr/g, '<br>')
    .replace(/@opendata="([^"]+)"/g, `<p contentEditable="false" data="$1">`)
    .replace(/@open/g, `<p contentEditable="false" data="${value}">`)
    .replace(/@close/g, '</p>');
};

const MentionInput = ({
  callback,
}: {
  callback: (data: { comment: string; usersId: string[] }) => Promise<void>;
}) => {
  const ref = useRef<any>();
  const refInput = useRef<any>();
  const position = useRef(0);
  const prevLength = useRef(0);

  const theme = useSelector((state: TState) => state.theme);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const userList = useSelector((state: TState) => state.userList);
  const filteredUsers = userList.filter((u) => !u.noNotif);
  const userById = keyBy(filteredUsers, '_id');

  const focus = () => {
    onClose();
    setCursorPosition(refInput.current, position.current);
  };

  const handleInputChange = (newVal: string) => {
    const textArea = document.createElement('div');
    textArea.innerHTML = newVal;

    const isDelete =
      textArea.textContent?.length &&
      prevLength.current > textArea.textContent?.length;

    const cursorPosition = getCursorPosition(refInput.current);
    const currChar = (textArea.textContent || '')[cursorPosition - 1];
    const prevChar = (textArea.textContent || '')[cursorPosition - 2];

    position.current = cursorPosition || 0;

    if (currChar === '@' && !prevChar?.trim() && !isDelete) {
      ref.current.focus();
      onOpen();
    } else {
      onClose();
    }

    prevLength.current = textArea.textContent?.length || 0;
  };

  const handleUserSelect = (e: any) => {
    const html = insertAtPosition(
      refInput.current.innerHTML || '',
      e.label,
      e.value,
      position.current,
    );

    refInput.current.innerHTML = html;
    position.current += e.label.length + 1;

    const textArea = document.createElement('div');
    textArea.innerHTML = refInput.current.innerHTML;
    prevLength.current = textArea.textContent?.length || 0;

    focus();
  };

  const handleSubmit = async () => {
    const usersId = uniq(
      refInput.current.innerHTML
        .split('data="')
        .map((s: string) => userById[s.split('">')[0]]?._id)
        .filter((f: string) => f),
    ) as string[];

    await callback({
      comment: refInput.current.innerHTML.replace(
        /<span[^>]*>(.*?)<\/span>/g,
        '$1',
      ),
      usersId,
    });

    refInput.current.innerHTML = '';
    prevLength.current = 0;
  };

  useEffect(() => {
    const handlePaste = (event: ClipboardEvent) => {
      event.preventDefault();

      // Extract plain text from the clipboard
      const clipboardData =
        event.clipboardData || (window as any).clipboardData;
      const pastedText = clipboardData.getData('text/plain'); // Sanitize to plain text

      position.current = getCursorPosition(refInput.current);

      const html = insertAtPosition(
        refInput.current.innerHTML || '',
        pastedText,
        null,
        position.current,
        true,
      );

      refInput.current.innerHTML = html;
      position.current += pastedText.length;

      const textArea = document.createElement('div');
      textArea.innerHTML = refInput.current.innerHTML;
      prevLength.current = textArea.textContent?.length || 0;

      focus();
    };

    const inputElement = refInput.current;

    if (inputElement) {
      inputElement.addEventListener('paste', handlePaste);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('paste', handlePaste);
      }
    };
  }, []);

  return (
    <>
      <Flex
        flexGrow={0}
        alignItems='flex-end'
        zIndex={1}
        position='relative'
        id='comment-input'
      >
        <Box
          ref={refInput}
          w='100%'
          border='1px solid'
          borderColor={theme.dark}
          borderRadius={5}
          mr={3}
          p={2}
          contentEditable
          onInput={(e: any) => {
            const newValue =
              e.target.innerHTML === '<br>' ? '' : e.target.innerHTML;

            handleInputChange(newValue);
          }}
        />
        <Button onClick={handleSubmit}>
          <Icon as={allIcons.HiMiniPaperAirplane} />
        </Button>
      </Flex>
      <RSelect
        styles={{
          control: (base: any) => ({
            ...base,
            fontSize: '12px',
            marginTop: '-40px',
            opacity: 0,
            zIndex: -1,
            position: 'relative',
          }),
          menu: (base: any) => ({
            ...base,
            fontSize: '12px',
            opacity: 1,
          }),
        }}
        ref={ref}
        menuIsOpen={isOpen}
        onChange={handleUserSelect}
        onKeyDown={(e) => (e.key === 'Escape' || e.keyCode === 27) && focus()}
        options={filteredUsers.map((u) => ({ label: u.name, value: u._id }))}
      />
    </>
  );
};

export default MentionInput;
